import React, { useState } from "react";
import MetaData from "../layouts/MetaData";
import { useDispatch, useSelector } from "react-redux";
import { saveShippingInfo } from "../../actions/cartAction";
import CheckoutSteps from "./CheckoutSteps";

const Shipping = ({history}) => {
    const dispatch = useDispatch()
    const {shippingInfo} = useSelector(state=>state.cart)
    const [localAddress, setLocalAddress] = useState(shippingInfo.address)
    const [city, setCity] = useState(shippingInfo.city)
    // const [postalCode, setPostalCode] = useState(shippingInfo.postalCode)
    const [phoneNo, setPhoneNo] = useState(shippingInfo.phoneNo)
    const [country, setCountry] = useState("UAE")

    const cities = ["", "Dubai", "Abu Dhabi", "Sharjah", "Ajman", "Umm Al-Quwain", "Fujairah", "RAK"]

    const handleSubmit = (e)=>{
        e.preventDefault();
        dispatch(saveShippingInfo({localAddress, city, phoneNo, country}))
        history.push('/confirm')
    }

  return (
    <>
    <MetaData title={'Shipping Info'}/>
    <CheckoutSteps shipping/>
      <div className="row wrapper">
        <div className="col-10 col-lg-5">
          <form onSubmit={handleSubmit} className="shadow-lg">
            <h1 className="mb-4">Shipping Info</h1>
            <div className="form-group">
              <label htmlFor="address_field">Local Address</label>
              <input
                type="text"
                id="address_field"
                 className="form-control"
                value={localAddress}
                onChange={e=>setLocalAddress(e.target.value)}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="city_field">State</label>
              <select
                type="text"
                id="city_field"
                className="form-control"
                value={city}
                onChange={e=>setCity(e.target.value)}
                required
              >
                {cities.map((city)=>(
                  <option key={city} value={city}>{city}</option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="phone_field">Phone No</label>
              <input
                type="phone"
                id="phone_field"
                className="form-control"
                value={phoneNo}
                onChange={e=>setPhoneNo(e.target.value)}
                required
              />
            </div>

            {/* <div className="form-group">
              <label htmlFor="postal_code_field">Postal Code</label>
              <input
                type="number"
                id="postal_code_field"
                className="form-control"
                value={postalCode}
                onChange={e=>setPostalCode(e.target.value)}
                required
              />
            </div> */}

            <div className="form-group">
              <label htmlFor="country_field">Country</label>
              <input
                type="text"
                id="postal_code_field"
                className="form-control"
                value={country}
                onChange={e=>setCountry(e.target.value)}
                required
              />
            </div>

            <button
              id="shipping_btn"
              type="submit"
              className="btn btn-block py-3"
            >
              CONTINUE
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Shipping;
