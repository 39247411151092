import React, { useState, useEffect, useRef } from "react";
import MetaData from "./layouts/MetaData";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../actions/productAction";
import Product from "./products/Product";
import Loader from "./layouts/Loader";
import { useAlert } from "react-alert";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faMoneyBillWave,
  faCheckCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Fade, Bounce } from "react-reveal";
import ShopOnlineInUAE from "./layouts/ShopOnlineInUAE";
import SaveYourBuy from "./layouts/SaveYourBuy";
import FeaturedCollections from "./layouts/FeaturedCollections";
import EcommAttributes from "./layouts/EcommAttributes";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const Home = ({ match }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [price, setPrice] = useState([1, 1000]);
  const [category, setCategory] = useState("");
  const [rating, setRating] = useState(0);

  const categories = [
    "Men Garments",
    "Women Garments",
    "Children Garments",
    "Men Footwear",
    "Women Footwear",
    "Children Footwear",
    "Personal Care",
    "Mother & Baby Care",
    "Makeup & Cosmetics",
    "Gifts & Perfumes",
  ];
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, products, error } = useSelector((state) => state.products);

  const history = useHistory();
  const keyword = match.params.keyword;

  useEffect(() => {
    if (error) {
      return alert.error(error);
    }
    dispatch(getProducts(keyword, currentPage, price, category, rating));

    const handleScroll = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY.toString());
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    dispatch,
    alert,
    error,
    keyword,
    currentPage,
    price,
    category,
    rating,
    history.location.search,
  ]);

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");

    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
    }

    // Clear the scroll position from session storage after restoring it
    sessionStorage.removeItem("scrollPosition");
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title={"Buy Quality for Less"} content="og:image" />

          <section id="products">
            <Fade duration={2000}>
              <div className="row">
                {keyword ? (
                  <>
                    <div className="col-sm-6 col-md-3 mt-5 d-none d-sm-inline">
                      <div className="px-5">
                        <Range
                          marks={{ 1: `AED 1`, 1000: `AED 1000` }}
                          min={1}
                          max={1000}
                          default={[1, 1000]}
                          tipFormatter={(value) => `AED ${value}`}
                          tipProps={{
                            placement: "top",
                            visible: true,
                          }}
                          value={price}
                          onChange={(price) => setPrice(price)}
                        />
                        <hr className="my-5" />
                        <div className="mt-5">
                          <h4 className="mb-3">Categories</h4>

                          <ul className="pl-0">
                            {categories.map((category) => (
                              <li
                                style={{
                                  cursor: "pointer",
                                  listStyleType: "none",
                                }}
                                key={category}
                                onClick={() => setCategory(category)}
                              >
                                {category}
                              </li>
                            ))}
                          </ul>
                        </div>

                        <hr className="my-5" />
                        <div className="mt-5">
                          <h4 className="mb-3">Ratings</h4>

                          <ul className="pl-0">
                            {[5, 4, 3, 2, 1].map((star) => (
                              <li
                                style={{
                                  cursor: "pointer",
                                  listStyleType: "none",
                                }}
                                key={star}
                                onClick={() => setRating(star)}
                              >
                                <div className="rating-outer">
                                  <div
                                    className="rating-inner"
                                    style={{ width: `${star * 20}%` }}
                                  ></div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-md-9">
                      <div className="row">
                        {products.map((product) => (
                          <Product
                            key={product._id}
                            product={product}
                            col={4}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                   <ShopOnlineInUAE />
                    <SaveYourBuy />
                    <hr />
                    <div className="container">
                    {/* <div className="col-md-12 text-center my-4 h-50">
                      <Link to="/discountproducts">
                        <img
                          src="images/SummerSale1.jpg"
                          alt="SummerSale1"
                          className="img-fluid mr-2 animated summer-sale-img"
                        />
                      </Link>
                    </div> */}
                    </div>
                    <FeaturedCollections />
                    <hr />
                    <EcommAttributes />
                  </>
                )}
              </div>
            </Fade>
          </section>
        </>
      )}
    </div>
  );
};

export default Home;
