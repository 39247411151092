import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MetaData";
import Loader from "../layouts/Loader";
import Sidebar from "./Sidebar";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { allOrders, deleteOrder, clearErrors } from "../../actions/orderAction";
import { DELETE_ORDER_RESET } from "../../constants/orderConstants";
import DeleteConfirmationModal from "../layouts/DeleteConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";

const OrdersList = ({ history }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = Number(localStorage.getItem("datatable-page"));
    return storedPage || 1;
  });
  const [ordersPerPage, setOrdersPerPage] = useState(() => {
    const storedOrdersPerPage = Number(localStorage.getItem("orders-per-page"));
    return storedOrdersPerPage || 10;
  }); // Initial value for products per page
  const [searchQuery, setSearchQuery] = useState("");
  const alert = useAlert();
  const dispatch = useDispatch();

  const { loading, error, orders } = useSelector((state) => state.allOrders);
  const { isDeleted } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(allOrders());

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success("Order deleted successfully");
      history.push("/admin/orders");
      dispatch({ type: DELETE_ORDER_RESET });
    }
  }, [dispatch, alert, error, isDeleted, history]);

  useEffect(() => {
    localStorage.setItem("datatable-page", currentPage);
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem("orders-per-page", ordersPerPage);
  }, [ordersPerPage]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDelete = () => {
    dispatch(deleteOrder(selectedOrderId));
    setShowDeleteModal(false);
  };

  const handleClose = () => {
    setShowDeleteModal(false);
  };
  const handleOrdersPerPageChange = (e) => {
    const value = parseInt(e.target.value);
    setOrdersPerPage(value);
    setCurrentPage(1); // Reset to the first page when changing products per page
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;

  // Define a custom sorting function to sort orders by status
  const sortByOrderStatus = (a, b) => {
    // Define the order of status you want (e.g., "Processing" first, "Delivered" last)
    const orderStatusOrder = [
      "Processing",
      "Shipped",
      "Delivered",
      "Cancelled",
    ];

    // Find the index of the order status in the predefined order array
    const indexA = orderStatusOrder.indexOf(a.orderStatus);
    const indexB = orderStatusOrder.indexOf(b.orderStatus);

    // Compare the indices to determine the order of sorting
    return indexA - indexB;
  };

  // Check if orders is null or undefined, and display a loader or fallback message
  if (loading || orders === null || orders === undefined) {
    return <Loader />; // Or render a fallback message like "Loading..."
  }
  // Sort the orders based on createdAt in descending order (latest order first)
  const sortedOrders = [...orders].sort(sortByOrderStatus);

  // Calculate the range of orders being displayed
  const currentOrdersStart = indexOfFirstOrder + 1;
  const currentOrdersEnd = Math.min(indexOfLastOrder, orders.length);
  const currentOrders = sortedOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  // Filter the products based on the search query
  const filteredOrders = sortedOrders.filter((order) =>
    order._id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(orders.length / ordersPerPage);
  return (
    <Fragment>
      <MetaData title={"All Orders"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        <div className="col-12 col-md-10">
          <Fragment>
            <h1 className="my-5">All Orders</h1>
            <div className="row my-2">
              <div className="form-group col-sm-5 flex-column d-flex">
                <label htmlFor="name_field">Entries per page:</label>
                <select
                  id="productsPerPage"
                  className="form-control"
                  type="number"
                  value={ordersPerPage}
                  onChange={handleOrdersPerPageChange}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  {/* Add more options for different products per page if desired */}
                </select>
              </div>
              <div className="ml-auto mr-2 form-group col-sm-5 flex-column d-flex">
                <label htmlFor="name_field">Search</label>
                <input
                  type="text"
                  id="name_field"
                  className="form-control"
                  placeholder="Search For Order"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : orders ? (
              <div className="mr-2">
                <table className="table table-bordered table-striped table-responsive">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Order Date</th>
                      <th>No of Items</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchQuery
                      ? filteredOrders.map((order) => (
                          <tr key={order._id}>
                            <td>{order._id}</td>
                            <td>{order.createdAt}</td>
                            <td>{order.orderItems.length}</td>
                            <td>AED {order.totalPrice}</td>
                            <td
                              className={
                                order.orderStatus === "Processing"
                                  ? "bg-danger text-white"
                                  : order.orderStatus === "Shipped"
                                  ? "bg-warning text-white"
                                  : order.orderStatus === "Delivered"
                                  ? "bg-success text-white"
                                  : ""
                              }
                            >
                              {order.orderStatus}
                            </td>

                            <td>
                              <Fragment>
                                <Link
                                  to={`/admin/order/${order._id}`}
                                  className="py-1 px-1"
                                >
                                  <FontAwesomeIcon icon={faEye} className="text-info"/>
                                </Link>
                                <Link
                                  to="#"
                                  className="py-1 px-1"
                                  onClick={() => {
                                    setShowDeleteModal(true);
                                    setSelectedOrderId(order._id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="text-danger"
                                  />
                                </Link>
                              </Fragment>
                            </td>
                          </tr>
                        ))
                      : currentOrders.map((order) => (
                          <tr key={order._id}>
                            <td>{order._id}</td>
                            <td>{order.createdAt}</td>
                            <td>{order.orderItems.length}</td>
                            <td>AED {order.totalPrice}</td>
                            <td
                              className={
                                order.orderStatus === "Processing"
                                  ? "bg-danger text-white"
                                  : order.orderStatus === "Shipped"
                                  ? "bg-warning text-white"
                                  : order.orderStatus === "Delivered"
                                  ? "bg-success text-white"
                                  : ""
                              }
                            >
                              {order.orderStatus}
                            </td>

                            <td>
                              <Fragment>
                              <Link
                                  to={`/admin/order/${order._id}`}
                                  className="py-1 px-1"
                                >
                                  <FontAwesomeIcon icon={faEye} className="text-info"/>
                                </Link>
                                <Link
                                  to="#"
                                  className="py-1 px-1"
                                  onClick={() => {
                                    setShowDeleteModal(true);
                                    setSelectedOrderId(order._id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="text-danger"
                                  />
                                </Link>
                              </Fragment>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
                <p className="mb-2">
                  Showing {currentOrdersStart} to {currentOrdersEnd} of{" "}
                  {orders.length} entries
                </p>
              </div>
            ) : (
              <>No orders Found</>
            )}

            {/* ... (optional: custom pagination controls if needed) */}
            <div className="d-flex justify-content-center">
              <nav aria-label="Page navigation">
                <ul className="pagination bg-white">
                  {/* Add previous button */}
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link" onClick={handlePreviousPage}>
                      Previous
                    </button>
                  </li>

                  {Array.from({ length: totalPages }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        index + 1 === currentPage ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => onPageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}

                  {/* Add next button */}
                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <button className="page-link" onClick={handleNextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </Fragment>
        </div>
      </div>
      <DeleteConfirmationModal
        show={showDeleteModal}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </Fragment>
  );
};

export default OrdersList;
