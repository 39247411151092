import React from "react";
import { useLocation, matchPath } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Link, Route } from "react-router-dom";
import Search from "./Search";

function MenuHeader() {
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";
  const isAllProducts = location.pathname === "/admin/products";
  const isAddNewProduct = location.pathname === "/admin/product";
  const isAllUsers = location.pathname === "/admin/users";
  const isAllOrders = location.pathname === "/admin/orders";
  const isReviews = location.pathname === "/admin/reviews";
  const isProductUpdate = !!matchPath(location.pathname, {
    path: "/admin/product/:id",
    exact: true,
    strict: false,
  });
  
  const isOrderProcess = !!matchPath(location.pathname, {
    path: "/admin/order/:id",
    exact: true,
    strict: false,
  });
  
  const isUserUpdate = !!matchPath(location.pathname, {
    path: "/admin/user/:id",
    exact: true,
    strict: false,
  });
  const isOutOfStock = location.pathname === "/admin/outofstock";
  const isOfferItems = location.pathname === "/admin/discountitems";

  if (
    isDashboard ||
    isAllProducts ||
    isAddNewProduct ||
    isAllUsers ||
    isAllOrders ||
    isReviews ||
    isProductUpdate ||
    isOrderProcess ||
    isUserUpdate ||
    isOutOfStock ||
    isOfferItems
  ) {
    // Return null if not on the home page to hide the carousel
    return null;
  }
  return (
    <div className="navbar navbar-expand-lg navbar-light menu_header d-flex flex-row">
      <div className="col-10 col-md-4 d-lg-none ">
        <Route render={({ history }) => <Search history={history} />} />
      </div>
      <button
        className="navbar-toggler bg-white"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon text-white"></span>
      </button>
      <div className="container">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a className="nav-link text-white" href="/">
                Home <span className="sr-only">(current)</span>
              </a>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link text-white"
                href="/Garments"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Garments
                <span className="ml-1">
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </a>

              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item text-white" href="/mengarments">
                  Men
                </a>
                <a className="dropdown-item text-white" href="/womengarments">
                  Women
                </a>
                <a
                  className="dropdown-item text-white"
                  href="/childrengarments"
                >
                  Children
                </a>
              </div>
            </li>

            <li className="nav-item dropdown">
              <a
                className="nav-link text-white"
                href="/Footwear"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Footwear
                <span className="ml-1">
                  <FontAwesomeIcon icon={faCaretDown} />
                </span>
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item text-white" href="/menfootwear">
                  Men
                </a>
                <a className="dropdown-item text-white" href="/womenfootwear">
                  Women
                </a>
                <a
                  className="dropdown-item text-white"
                  href="/childrenfootwear"
                >
                  Children
                </a>
              </div>
            </li>

            <li className="nav-item">
              <a className="nav-link text-white" href="/personalcare">
                Personal Care
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="/motherbabycare">
                Mother & Baby Care
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-white" href="/makeupncosmetics">
                Makeup & Cosmetics
              </a>
            </li>

            {/* <li className="nav-item">
              <a className="nav-link text-white" href="/homelinens">
                Home Linens
              </a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link text-white" href="/giftitems">
                Gifts & Perfumes
              </a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link text-white" href="/imitationjewellery">
                Imitation Jewellery
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default MenuHeader;
