import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { verifyEmail, clearErrors } from "../../actions/userAction";
import MetaData from "../layouts/MetaData";

const VerificationSuccess = ({ history, match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { error, loading } = useSelector((state) => state.verifyEmail);

  useEffect(() => {
    dispatch(verifyEmail(match.params.token));

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, alert, error, match.params.token]);

  return (
    <>
      <MetaData title={"Verification Success"} />
      <div className="container container-fluid">
        <div className="row justify-content-center">
          <div className="col-6 mt-5 text-center">
            {/* {loading ? (
              <h4>Verifying your account...</h4>
            ) : ( */}
              <>
                <h4>Your account has been successfully verified!</h4>
                <p>You can now login to access your account.</p>
                <button
                  className="btn btn-primary"
                  onClick={() => history.push("/login")}
                >
                  Login
                </button>
              </>
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default VerificationSuccess;
