import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsByCategory } from "../../actions/productAction";
import Product from "../products/Product";
import MetaData from "../layouts/MetaData";

const MakeupnCosmetics = () => {
  const dispatch = useDispatch();
  const { loading, products, error } = useSelector(
    (state) => state.productByCategory
  );

  useEffect(() => {
    const category = "MakeupNCosmetics"; // Replace with the desired category
    dispatch(fetchProductsByCategory(category));
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <MetaData title={"Makeup & Cosmetics"} />
      <h1 id="products_heading" className="artah-text-purple">Makeup & Cosmetics</h1>

      <section id="products" className="container mt-5">
        <div className="row">
          {products.map((product) => (
            <Product key={product._id} product={product} col={3} />
          ))}
        </div>
      </section>
    </>
  );
};

export default MakeupnCosmetics;
