import React from "react";
import "../../App.css";

import { Link, useLocation, matchPath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { logout } from "../../actions/userAction";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";

function TopHeader() {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { user, isAuthenticated, loading } = useSelector((state) => state.auth);
  const logoutHandler = () => {
    dispatch(logout());

    alert.success("Successfully Logged out");
  };

  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";
  const isAllProducts = location.pathname === "/admin/products";
  const isAddNewProduct = location.pathname === "/admin/product";
  const isAllUsers = location.pathname === "/admin/users";
  const isAllOrders = location.pathname === "/admin/orders";
  const isReviews = location.pathname === "/admin/reviews";
  const isProductUpdate = !!matchPath(location.pathname, {
    path: "/admin/product/:id",
    exact: true,
    strict: false,
  });
  
  const isOrderProcess = !!matchPath(location.pathname, {
    path: "/admin/order/:id",
    exact: true,
    strict: false,
  });
  
  const isUserUpdate = !!matchPath(location.pathname, {
    path: "/admin/user/:id",
    exact: true,
    strict: false,
  });
  const isOutOfStock = location.pathname === "/admin/outofstock";
  const isOfferItems =location.pathname==="/admin/discountitems";

  if (
    isDashboard ||
    isAllProducts ||
    isAddNewProduct ||
    isAllUsers ||
    isAllOrders ||
    isReviews ||
    isProductUpdate ||
    isOrderProcess ||
    isUserUpdate ||
    isOutOfStock ||
    isOfferItems
  ) {
    // Return null if not on the home page to hide the carousel
    return null;
  }
  return (
    <div className="navbar artah-purple">
      <div className="container">
        <div className="navbar navbar-expand-sm d-none d-sm-inline">
          <a
            href="https://facebook.com/artahllc"
            className="social-media-icons text-warning"
            target="_ blank"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            href="https://instagram.com/artahllc"
            className="social-media-icons text-warning"
            target="_ blank"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://tiktok.com/@artahllc"
            className="social-media-icons text-warning"
            target="_ blank"
          >
            <FontAwesomeIcon icon={faTiktok} />
          </a>
        </div>
        <div className="justify-content-center text-white marquee-container ">
          <div className="marquee-content">
            <span className="marquee-text mx-3">Buy Quality for Less</span>
            <span className="text-white">|</span>
            <span className="marquee-text mx-3">
              Free Delivery over AED 150 across UAE
            </span>
          </div>
        </div>
        {/* <p className="justify-content-center my-auto text-white marquee-container">Buy Quality for Less</p>
        <p className="justify-content-center my-auto text-white marquee-container">Free Delivery over AED 150 across UAE</p> */}

        <div className="row d-none d-sm-inline">
          {isAuthenticated && user.isVerified ? (
            <div className="ml-4 dropdown d-inline d-none d-sm-inline">
              <Link
                to="#!"
                className="btn dropdown-toggle text-white mr-4"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <figure className="avatar avatar-nav">
                  <img
                    src={user.avatar && user.avatar.url}
                    alt={user && user.name}
                    className="rounded-circle"
                  />
                </figure>
                <span style={{ color: "#e69637" }}>{user && user.name}</span>
              </Link>
              <div
                className="dropdown-menu"
                aria-labelledby="dropDownMenuButton"
              >
                {user && user.role === "admin" && (
                  <Link className="dropdown-item text-white" to="/dashboard">
                    Dashboard
                  </Link>
                )}
                <Link className="dropdown-item text-white" to="/orders/me">
                  Orders
                </Link>
                <Link className="dropdown-item text-white" to="/me">
                  Profile
                </Link>
                <Link
                  className="dropdown-item text-white"
                  to="/"
                  onClick={logoutHandler}
                >
                  Logout
                </Link>
              </div>
            </div>
          ) : (
            !loading && (
              <>
                <a
                  className="nav-link text-warning d-none d-sm-inline"
                  href="/login"
                >
                  Login
                </a>

                <span className="text-white my-auto d-none d-sm-inline">|</span>

                <a
                  className="nav-link text-warning d-none d-sm-inline"
                  href="/register"
                >
                  Register
                </a>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
