import React from "react";
import { useLocation, matchPath } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const location = useLocation();
  
  const isDashboard = location.pathname === "/dashboard";
  const isAllProducts = location.pathname === "/admin/products";
  const isAddNewProduct = location.pathname === "/admin/product";
  const isAllUsers = location.pathname === "/admin/users";
  const isAllOrders = location.pathname === "/admin/orders";
  const isReviews = location.pathname === "/admin/reviews";
  const isOutOfStock = location.pathname === "/admin/outofstock";
  const isOfferItems = location.pathname=== "/admin/discountitems";
  const isProductUpdate = !!matchPath(location.pathname, {
    path: "/admin/product/:id",
    exact: true,
    strict: false,
  });
  
  const isOrderProcess = !!matchPath(location.pathname, {
    path: "/admin/order/:id",
    exact: true,
    strict: false,
  });
  
  const isUserUpdate = !!matchPath(location.pathname, {
    path: "/admin/user/:id",
    exact: true,
    strict: false,
  });

  if (
    isDashboard ||
    isAllProducts ||
    isAddNewProduct ||
    isAllUsers ||
    isAllOrders ||
    isReviews ||
    isOutOfStock ||
    isOfferItems ||
    isProductUpdate ||
    isUserUpdate ||
    isOrderProcess
  ) {
    // Return null if not on the home page to hide the carousel
    return null;
  }

  return (
    <footer className="text-white">
      <div
        style={{ backgroundColor: "#ed5922", width: "100%", height: "5px" }}
      ></div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-3">
            <h4>Artah LLC</h4>
            <p style={{ fontSize: "14px" }}>
              Artah enterprices come to the customer with the slogan of Buy
              Quality for Less, based in Dubai UAE Artah provides clothing
              apparel that are in vogue. Artah has been exploring the vogue of
              fashion including men and women, baby care, shoes, perfume and
              cosmetics, imitation jewellery, beauty ,hair care and more. we
              would warmly like to invite you to visit our online retail shop
              and contact for our latest collection.
            </p>
          </div>
          <div className="col-md-3">
            <h4>Quick Links</h4>
            <ul className="list-unstyled">
              <li>
                <a href="/" style={{ color: "#ffffff", fontSize: "14px" }}>
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/mengarments"
                  style={{ color: "#ffffff", fontSize: "14px" }}
                >
                  Men Garments
                </a>
              </li>
              <li>
                <a
                  href="/womengarments"
                  style={{ color: "#ffffff", fontSize: "14px" }}
                >
                  Women Garments
                </a>
              </li>
              <li>
                <a
                  href="/childrengarments"
                  style={{ color: "#ffffff", fontSize: "14px" }}
                >
                  Children Garments
                </a>
              </li>
              <li>
                <a
                  href="/menfootwear"
                  style={{ color: "#ffffff", fontSize: "14px" }}
                >
                  Men Footwear
                </a>
              </li>
              <li>
                <a
                  href="/womenfootwear"
                  style={{ color: "#ffffff", fontSize: "14px" }}
                >
                  Women Footwear
                </a>
              </li>
              <li>
                <a
                  href="/childrenfootwear"
                  style={{ color: "#ffffff", fontSize: "14px" }}
                >
                  Children Footwear
                </a>
              </li>
              <li>
                <a
                  href="/personalcare"
                  style={{ color: "#ffffff", fontSize: "14px" }}
                >
                  Personal Care
                </a>
              </li>
              <li>
                <a
                  href="/motherbabycare"
                  style={{ color: "#ffffff", fontSize: "14px" }}
                >
                  Mother & Baby Care
                </a>
              </li>
              <li>
                <a
                  href="/makeupncosmetics"
                  style={{ color: "#ffffff", fontSize: "14px" }}
                >
                  Makeup & Cosmetics
                </a>
              </li>
              {/* <li>
                <a href="#" style={{ color: "#ffffff", fontSize: "14px" }}>
                  Home Linens
                </a>
              </li> */}
              <li>
                <a
                  href="/giftitems"
                  style={{ color: "#ffffff", fontSize: "14px" }}
                >
                  Gifts & Perfumes
                </a>
              </li>
              {/* <li>
                <a href="#" style={{ color: "#ffffff", fontSize: "14px" }}>
                  Imitation Jewellery
                </a>
              </li> */}
            </ul>
          </div>
          <div className="col-md-3">
            <h4>Contact Us</h4>

            <p>
              For general inquiries & customer support
              <br />
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <span style={{ color: "#E69637" }}>support@artahllc.com</span>
              <hr />
              For business related inquiries
              <br />
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <span style={{ color: "#E69637" }}>admin@artahllc.com</span>
            </p>
            <div>
              <a
                href="https://facebook.com/artahllc"
                className="social-media-icons"
                target="_ blank"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
              <a
                href="https://instagram.com/artahllc"
                className="social-media-icons"
                target="_ blank"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://tiktok.com/@artahllc"
                className="social-media-icons"
                target="_ blank"
              >
                <FontAwesomeIcon icon={faTiktok} />
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <h4>Information</h4>
            <ul className="list-unstyled">
              <li>
                <a href="/faq" style={{ color: "#ffffff" }}>
                  FAQ
                </a>
              </li>
              <li>
                <a href="#" style={{ color: "#ffffff" }}>
                  Return & Refund Policy
                </a>
              </li>
              <li>
                <a href="#" style={{ color: "#ffffff" }}>
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" style={{ color: "#ffffff" }}>
                  Terms and Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "white",
            width: "100%",
            height: "1px",
            float: "auto",
          }}
        ></div>
        <div className="row">
          <div className="col-md-12 text-center mt-2">
            <p style={{ fontSize: "12px" }}>
              &copy; 2023 Artah Enterprises for Readymade Garments LLC. All
              Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
