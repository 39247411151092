import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MetaData";
import Loader from "../layouts/Loader";
import Sidebar from "./Sidebar";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { allUsers, deleteUser, clearErrors } from "../../actions/userAction";
import { DELETE_USER_RESET } from "../../constants/userConstants";
import DeleteConfirmationModal from "../layouts/DeleteConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";

const UsersList = ({ history }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = Number(localStorage.getItem("datatable-page"));
    return storedPage || 1;
  });
  const [usersPerPage, setUsersPerPage] = useState(() => {
    const storedUsersPerPage = Number(localStorage.getItem("users-per-page"));
    return storedUsersPerPage || 10;
  }); // Initial value for products per page
  const [searchQuery, setSearchQuery] = useState("");
  const alert = useAlert();
  const dispatch = useDispatch();

  const { loading, error, users } = useSelector((state) => state.allUsers);
  const { isDeleted } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(allUsers());

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success("User deleted successfully");
      history.push("/admin/users");
      dispatch({ type: DELETE_USER_RESET });
    }
  }, [dispatch, alert, error, isDeleted, history]);

  useEffect(() => {
    localStorage.setItem("datatable-page", currentPage);
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem("users-per-page", usersPerPage);
  }, [usersPerPage]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDelete = () => {
    dispatch(deleteUser(selectedUserId));
    setShowDeleteModal(false);
  };
  const handleClose = () => {
    setShowDeleteModal(false);
  };

  const handleUsersPerPageChange = (e) => {
    const value = parseInt(e.target.value);
    setUsersPerPage(value);
    setCurrentPage(1); // Reset to the first page when changing products per page
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const totalPages = Math.ceil(users.length / usersPerPage);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  // Calculate the range of orders being displayed
  const currentUsersStart = indexOfFirstUser + 1;
  const currentUsersEnd = Math.min(indexOfLastUser, users.length);
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  // Filter the products based on the search query
  const filteredUsers = users.filter((user) =>{
    const searchQueryLowerCase = searchQuery.toLowerCase();

    // search by ID, name or email
    const emailMatch = user.email.toLowerCase().includes(searchQueryLowerCase);
    const nameMatch = user.name.toLowerCase().includes(searchQueryLowerCase);
    const idMatch = user._id.toLowerCase().includes(searchQueryLowerCase);

    return emailMatch || nameMatch|| idMatch ;

  });

  return (
    <>
      <MetaData title={"All Users"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        <div className="col-12 col-md-10">
          <>
            <h1 className="my-5">All Users</h1>
            <div className="row my-2">
              <div className="form-group col-sm-5 flex-column d-flex">
                <label htmlFor="name_field">Entries per page:</label>
                <select
                  id="productsPerPage"
                  className="form-control"
                  type="number"
                  value={usersPerPage}
                  onChange={handleUsersPerPageChange}
                >
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  {/* Add more options for different products per page if desired */}
                </select>
              </div>
              <div className="ml-auto mr-2 form-group col-sm-5 flex-column d-flex">
                <label htmlFor="name_field">Search</label>
                <input
                  type="text"
                  id="name_field"
                  className="form-control"
                  placeholder="Search For Order"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
              </div>
            </div>
            {loading ? (
              <Loader />
            ) : users ? (
              <div className="mr-2">
                <div className="table-responsive">
                  <table className="table table-bordered table-hover">
                    <thead>
                      <tr>
                        <th>User ID</th>
                        <th>Title</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchQuery ? filteredUsers.map((user) => (
                        <tr key={user._id}>
                          <td>{user._id}</td>
                          <td>{user.title}</td>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td
                            className={
                              user.isVerified === true
                                ? "bg-success text-white"
                                : "bg-danger text-white"
                            }
                          >
                            {user.isVerified === true
                              ? "Verified"
                              : "Un-Verified"}
                          </td>
                          <td>
                            <Fragment>
                              <Link
                                to={`/admin/user/${user._id}`}
                                className="py-1 px-2"
                              >
                                <FontAwesomeIcon
                                  icon={faPencil}
                                  className="text-info"
                                />
                              </Link>
                             { user.role!=="admin" && <Link
                                to="#"
                                className="py-1 px-2"
                                onClick={() => {
                                  setShowDeleteModal(true);
                                  setSelectedUserId(user._id);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="text-danger"
                                  disabled={user.role === "admin"}
                                />
                              </Link>}
                              <DeleteConfirmationModal
                                show={showDeleteModal}
                                handleClose={handleClose}
                                handleDelete={handleDelete}
                              />
                            </Fragment>
                          </td>
                        </tr>
                      )):(
                        currentUsers.map((user) => (
                          <tr key={user._id}>
                            <td>{user._id}</td>
                            <td>{user.title}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            <td
                              className={
                                user.isVerified === true
                                  ? "bg-success text-white"
                                  : "bg-danger text-white"
                              }
                            >
                              {user.isVerified === true
                                ? "Verified"
                                : "Un-Verified"}
                            </td>
                            <td>
                              <Fragment>
                                <Link
                                  to={`/admin/user/${user._id}`}
                                  className="py-1 px-2"
                                >
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    className="text-info"
                                  />
                                </Link>
                               {user.role!=="admin" && <Link
                                  to="#"
                                  className="py-1 px-2"
                                  onClick={() => {
                                    setShowDeleteModal(true);
                                    setSelectedUserId(user._id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="text-danger"
                                    disabled={user.role === "admin"}
                                  />
                                </Link>}
                                <DeleteConfirmationModal
                                  show={showDeleteModal}
                                  handleClose={handleClose}
                                  handleDelete={handleDelete}
                                />
                              </Fragment>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                  <p className="mb-2">
                    Showing {currentUsersStart} to {currentUsersEnd} of{" "}
                    {users.length} entries
                  </p>
                </div>
                {/* ... (optional: custom pagination controls if needed) */}
                <div className="d-flex justify-content-center">
                  <nav aria-label="Page navigation">
                    <ul className="pagination bg-white">
                      {/* Add previous button */}
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={handlePreviousPage}
                        >
                          Previous
                        </button>
                      </li>

                      {Array.from({ length: totalPages }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            index + 1 === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => onPageChange(index + 1)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}

                      {/* Add next button */}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button className="page-link" onClick={handleNextPage}>
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            ):(
              <h3>User Not Found</h3>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default UsersList;
