import React, { useState, useEffect, useRef } from "react";
import MetaData from "../layouts/MetaData";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../actions/productAction";
import Product from "../products/Product";
import Loader from "../layouts/Loader";
import { useAlert } from "react-alert";
import Pagination from "react-js-pagination";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { useHistory, Link, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const OutOfStock = ({ match }) => {
  const [currentPage, setCurrentPage] = useState(() =>
    sessionStorage.getItem("currentPage")
      ? Number(sessionStorage.getItem("currentPage"))
      : 1
  );
  const [price, setPrice] = useState([1, 1000]);
  const [category, setCategory] = useState("");
  const [rating, setRating] = useState(0);
  const [showSaleProducts, setShowSaleProducts] = useState(false);
  const categories = [
    "Men Garments",
    "Women Garments",
    "Children Garments",
    "Men Footwear",
    "Women Footwear",
    "Children Footwear",
    "Personal Care",
    "Mother & Baby Care",
    "Makeup & Cosmetics",
    "Home Linens",
    "Gift Items",
    "Imitation Jewellery",
  ];
  const alert = useAlert();
  const dispatch = useDispatch();
  const {
    loading,
    products,
    error,
    productsCount,
    resPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products);

  const history = useHistory();
  const keyword = match.params.keyword;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get("page");

  useEffect(() => {
    if (error) {
      return alert.error(error);
    }
    dispatch(
      getProducts(
        keyword,
        currentPage,
        price,
        category,
        rating,
        showSaleProducts
      )
    );

    const handleScroll = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY.toString());
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    dispatch,
    alert,
    error,
    keyword,
    currentPage,
    price,
    category,
    rating,
    showSaleProducts,
    history.location.search,
  ]);

  useEffect(() => {
    const productId = sessionStorage.getItem("productId");
    const storedPage = sessionStorage.getItem("currentPage");

    if (productId) {
      const productElement = document.getElementById(productId);
      if (productElement) {
        const scrollPosition = productElement.offsetTop;
        window.scrollTo(0, scrollPosition);
      }
      setCurrentPage(Number(storedPage));
      // Clear the stored values from session storage
      sessionStorage.removeItem("productId");
      sessionStorage.removeItem("currentPage");
    }

    if (page) {
      setCurrentPage(Number(page));
    }
  }, [page]);

  useEffect(() => {
    // Store the current page number in session storage
    sessionStorage.setItem("currentPage", currentPage.toString());
  }, [currentPage]);

  const setCurrentPageNo = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  let count = productsCount;
  if (keyword) {
    count = filteredProductsCount;
  }

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title={"Out Of Stock Products"} />
          <>
            <div className="row">
              <div className="col-12 col-md-2">
                <Sidebar />
              </div>

              <div className="container col-10 col-md-10 col-sm-12">
                <div className="mt-5">
                  <h2 id="products_heading" className="artah-text-purple">
                    Out of Stock Products
                  </h2>
                </div>
                <div className="row">
                  {products &&
                    products
                      .filter((product) => product.stock === 0)
                      .map((product) => (
                        <Product
                          key={product._id}
                          product={product}
                          col={3}
                          id={product._id}
                          currentPage={currentPage}
                        />
                      ))}
                </div>

                {resPerPage <= count && (
                  <div className="d-flex justify-content-center mt-5">
                    <Pagination
                      activePage={currentPage}
                      itemsCountPerPage={resPerPage}
                      totalItemsCount={productsCount}
                      onChange={setCurrentPageNo}
                      nextPageText={"Next"}
                      prevPageText={"Prev"}
                      firstPageText={"First"}
                      lastPageText={"Last"}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        </>
      )}
    </div>
  );
};

export default OutOfStock;
