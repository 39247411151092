import React, {useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsByCategory } from "../../actions/productAction";
import Product from "../products/Product";
import MetaData from '../layouts/MetaData';

const MotherBabyCare = () => {
  const dispatch = useDispatch();
  const { loading, products, error } = useSelector(
    (state) => state.productByCategory
  );

  useEffect(() => {
    const category = "MotherNBabyCare"; // Replace with the desired category
    dispatch(fetchProductsByCategory(category));
  }, [dispatch]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <MetaData title={"Mother & Baby Care"} />
      <h1 id="products_heading" className="artah-text-purple">Mother & Baby Care</h1>

      <section id="products" className="container mt-5">
        <div className="row">
          
            {products.map((product) => (
              <Product key={product._id} product={product} col={3} />
            ))}
          
        </div>
      </section>
    </>
  );
};


export default MotherBabyCare