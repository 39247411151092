import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const Accordions = (props) => {
  return (
    <div className="accordion" id="faq-accordion">
      <div className="card">
        <div className="card-header" id={props.headingId}>
          <h5 className="mb-0">
            <button
              className={props.className}
              type="button"
              data-toggle="collapse"
              data-target={props.dataTarget}
              aria-expanded={props.isExpanded}
              aria-controls={props.ariaControls}
              style={{ color: "#E69637" }}
            >
              {props.qsn}
            </button>
          </h5>
        </div>

        <div
          id={props.id}
          className={props.class}
          aria-labelledby={props.ariaLabel}
          data-parent="#faq-accordion"
        >
          <div className="card-body ml-5" style={{ color: "#6E0D44" }}>
            {props.ans}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordions;
