import axios from "axios";
import {
  ADMIN_PRODUCTS_REQUEST,
  ADMIN_PRODUCTS_SUCCESS,
  ADMIN_PRODUCTS_FAIL,
  ALL_PRODUCTS_REQUEST,
  ALL_PRODUCTS_SUCCESS,
  ALL_PRODUCTS_FAIL,
  ALL_DISCOUNT_PRODUCTS_REQUEST,
  ALL_DISCOUNT_PRODUCTS_SUCCESS,
  ALL_DISCOUNT_PRODUCTS_FAIL,
  DISPLAY_IN_SAVE_YOUR_BUY_REQUEST,
  DISPLAY_IN_SAVE_YOUR_BUY_SUCCESS,
  DISPLAY_IN_SAVE_YOUR_BUY_FAIL,
  PRODUCTS_BY_CATEGORY_REQUEST,
  PRODUCTS_BY_CATEGORY_SUCCESS,
  PRODUCTS_BY_CATEGORY_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  PRODUCT_REVIEW_REQUEST,
  PRODUCT_REVIEW_SUCCESS,
  PRODUCT_REVIEW_FAIL,
  GET_REVIEWS_REQUEST,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAIL,
  DELETE_REVIEWS_REQUEST,
  DELETE_REVIEWS_SUCCESS,
  DELETE_REVIEWS_FAIL,
  CLEAR_ERRORS,
  PRODUCTS_WITHOUT_DISCOUNT_REQUEST,
  PRODUCTS_WITHOUT_DISCOUNT_SUCCESS,
  PRODUCTS_WITHOUT_DISCOUNT_FAIL,
} from "../constants/productConstants";

export const getProducts =
  (keyword = "", currentPage = 1, price, category, rating = 0) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ALL_PRODUCTS_REQUEST,
      });

      let link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&price[lte]=${price[1]}
      &price[gte]=${price[0]}&ratings[gte]=${rating}`;

      if (category) {
        link = `/api/v1/products?keyword=${keyword}&page=${currentPage}&price[lte]=${price[1]}&price[gte]=${price[0]}&category=${category}&ratings[gte]=${rating}`;
      }
      const { data } = await axios.get(link);

      dispatch({
        type: ALL_PRODUCTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_PRODUCTS_FAIL,
        payload: error.response.data.message,
      });
    }
  };

  export const getDiscountProducts =
  (keyword = "", currentPage = 1, price, category, rating = 0) =>
  async (dispatch) => {
    try {
      dispatch({
        type: ALL_DISCOUNT_PRODUCTS_REQUEST,
      });

      let link = `/api/v1/discountproducts?keyword=${keyword}&page=${currentPage}&price[lte]=${price[1]}
      &price[gte]=${price[0]}&ratings[gte]=${rating}`;

      if (category) {
        link = `/api/v1/discountproducts?keyword=${keyword}&page=${currentPage}&price[lte]=${price[1]}&price[gte]=${price[0]}&category=${category}&ratings[gte]=${rating}`;
      }
      const { data } = await axios.get(link);

      dispatch({
        type: ALL_DISCOUNT_PRODUCTS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_DISCOUNT_PRODUCTS_FAIL,
        payload: error.response.data.message,
      });
    }
  };


  export const getDisplayInSaveYourBuy =
  () =>
  async (dispatch) => {
    try {
      dispatch({
        type: DISPLAY_IN_SAVE_YOUR_BUY_REQUEST,
      });

      let link = `/api/v1/displayinsaveyourbuy`;

     
      const { data } = await axios.get(link);

      dispatch({
        type: DISPLAY_IN_SAVE_YOUR_BUY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DISPLAY_IN_SAVE_YOUR_BUY_FAIL,
        payload: error.response.data.message,
      });
    }
  };



  export const getProductsWithoutDiscount =
  () =>
  async (dispatch) => {
    try {
      dispatch({
        type: PRODUCTS_WITHOUT_DISCOUNT_REQUEST,
      });

      let link = `/api/v1/discountlessproducts`;

      const { data } = await axios.get(link);

      dispatch({
        type: PRODUCTS_WITHOUT_DISCOUNT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: PRODUCTS_WITHOUT_DISCOUNT_FAIL,
        payload: error.response.data.message,
      });
    }
  };

export const fetchProductsByCategory = (category) => async (dispatch) => {
  try {
    dispatch({ type: "PRODUCTS_BY_CATEGORY_REQUEST" });

    const { data } = await axios.get(`/api/v1/products?category=${category}`);

    dispatch({
      type: "PRODUCTS_BY_CATEGORY_SUCCESS",
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: "PRODUCTS_BY_CATEGORY_FAIL",
      payload: error.response.data.message,
    });
  }
};

export const getProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/product/${id}`);

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const getAdminProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: ADMIN_PRODUCTS_REQUEST,
    });

    const { data } = await axios.get(`/api/v1/admin/products/`);

    dispatch({
      type: ADMIN_PRODUCTS_SUCCESS,
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_PRODUCTS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get product reviews
export const getProductReviews = (reviewData) => async (dispatch) => {
  try {
    dispatch({
      type: PRODUCT_REVIEW_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(`/api/v1/review`, reviewData, config);

    dispatch({
      type: PRODUCT_REVIEW_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_REVIEW_FAIL,
      payload: error.response.data.message,
    });
  }
};

// add new product - admin
export const newProduct = (productData) => async (dispatch) => {
  try {
    dispatch({
      type: NEW_PRODUCT_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const { data } = await axios.post(
      "/api/v1/admin/product",
      productData,
      config
    );

    dispatch({
      type: NEW_PRODUCT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// delete product (Admin)
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_PRODUCT_REQUEST,
    });

    const { data } = await axios.delete(`/api/v1/admin/product/${id}`);

    dispatch({
      type: DELETE_PRODUCT_SUCCESS,
      payload: data.product,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// update product for admin
export const updateProduct = (id, productData) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PRODUCT_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.put(
      `/api/v1/admin/product/${id}`,
      productData,
      config
    );

    dispatch({
      type: UPDATE_PRODUCT_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get product reviews - ADMIN
export const getReviews = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REVIEWS_REQUEST,
    });
    const { data } = await axios.get(`/api/v1/reviews?id=${id}`);

    dispatch({
      type: GET_REVIEWS_SUCCESS,
      payload: data.reviews,
    });
  } catch (error) {
    dispatch({
      type: GET_REVIEWS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// delete product reviews - ADMIN
export const deleteReviews = (id, productId) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_REVIEWS_REQUEST,
    });
    const { data } = await axios.delete(
      `/api/v1/reviews?id=${id}&productId=${productId}`
    );

    dispatch({
      type: DELETE_REVIEWS_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_REVIEWS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// clear errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
