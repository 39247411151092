import React, { useState } from "react";
import MetaData from "../layouts/MetaData";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { addItemsToCart, removeCartItems } from "../../actions/cartAction";

const Cart = ({ history }) => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const { cartItems } = useSelector((state) => state.cart);

  const removeCartItemsHandler = (id) => {
    dispatch(removeCartItems(id));
  };
  const increaseQty = (id, quantity, stock) => {
    const newQty = quantity + 1;
    if (newQty > stock) {
      return;
    }
    dispatch(addItemsToCart(id, newQty));
  };

  const decreaseQty = (id, quantity) => {
    const newQty = quantity - 1;
    if (newQty <= 0) {
      return;
    }
    dispatch(addItemsToCart(id, newQty));
  };

  const handleCheckout = () => {
    history.push("/login?redirect=shipping");
  };
  return (
    <>
      <MetaData title={"Cart"} />
      {cartItems.length === 0 ? (
        <h2 className="mt-5">
          Cart is currently empty.{" "}
          <Link to="/" className="text-primary">
            Start Shopping
          </Link>{" "}
        </h2>
      ) : (
        <>
          <div>
            <h2 className="mt-5">
              Your Cart: <b>{cartItems.length} items</b>
            </h2>

            <div className="row d-flex justify-content-between">
              <div className="col-12 col-lg-8">
                {cartItems.map((item) => (
                  <>
                    <hr />
                    <div className="cart-item" key={item.product}>
                      <div className="row">
                        <div className="col-4 col-lg-3">
                          <img
                            src={item.image}
                            alt={item.name}
                            height="90"
                            width="115"
                          />
                        </div>

                        <div className="col-4 col-lg-3">
                          <Link to={`/products/${item.product}`}>
                            {item.name}
                          </Link>
                         <div className="mt-2">
                         <p>Size: 
                          {item.size ? ` ${item.size}`:" NA"}
                          </p>
                          <p>
                            Color:{item.color ?` ${item.color}`: " NA"}
                          </p>
                         </div>
                        </div>
                        
                        {item.discount ? (
                          <div className="col-4 col-lg-2 mt-4 mt-lg-0">
                            <p id="card_item_price">
                              AED {(item.price * (1 - item.discount / 100)).toFixed(2)}
                            </p>
                          </div>
                        ) : (
                          <div className="col-4 col-lg-2 mt-4 mt-lg-0">
                            <p id="card_item_price">AED {item.price.toFixed(2)}</p>
                          </div>
                        )}

                          
                        <div className="col-4 col-lg-3 mt-4 mt-lg-0">
                          
     
                          <div className="stockCounter d-inline">
                            <span
                              className="btn btn-danger minus"
                              onClick={() =>
                                decreaseQty(item.product, item.quantity)
                              }
                            >
                              -
                            </span>
                            <input
                              type="number"
                              className="form-control count d-inline"
                              value={item.quantity}
                              readOnly
                            />

                            <span
                              className="btn btn-primary plus"
                              onClick={() =>
                                increaseQty(
                                  item.product,
                                  item.quantity,
                                  item.stock
                                )
                              }
                            >
                              +
                            </span>

                            
                          </div>
                        </div>



                        <div className="col-4 col-lg-1 mt-4 mt-lg-0">
                          <i
                            id="delete_cart_item"
                            className="fa fa-trash btn btn-danger"
                            onClick={() => removeCartItemsHandler(item.product)}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <hr />
                  </>
                ))}
              </div>

              <div className="col-12 col-lg-3 my-4">
                <div id="order_summary">
                  <h4>Order Summary</h4>
                  <hr />
                  <p>
                    Subtotal:{" "}
                    <span className="order-summary-values">
                      {cartItems.reduce(
                        (acc, item) => acc + Number(item.quantity),
                        0
                      )}{" "}
                      (Units)
                    </span>
                  </p>
                  <p>
                    Est. total:{" "}
                    
                    <span className="order-summary-values">
                      AED
                      {cartItems.reduce(
                        (acc, item) => acc + item.quantity * (item.price * (1-item.discount/100)).toFixed(2),
                        0
                      )}
                    </span>
                  </p>

                  <hr />
                  <button
                    id="checkout_btn"
                    className="btn btn-primary btn-block"
                    onClick={handleCheckout}
                  >
                    Check out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Cart;
