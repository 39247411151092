import React from "react";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { logout } from "../../actions/userAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProductHunt } from "@fortawesome/free-brands-svg-icons";
import {
  faTachometer,
  faHome,
  faTag,
  faExclamationCircle,
  faPercent,
  faUsers,
  faStar,
  faClipboard,
  faShoppingBasket,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Sidebar = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { user, isAuthenticated, loading } = useSelector((state) => state.auth);
  const logoutHandler = () => {
    dispatch(logout());

    alert.success("Successfully Logged out");
  };
  return (
    <>
      <div className="sidebar-wrapper">
        <nav id="sidebar">
          {isAuthenticated && user.isVerified ? (
            <div className="dropdown">
              <Link
                to="#!"
                className="btn dropdown-toggle text-white mr-4"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <figure className="avatar avatar-nav">
                  <img
                    src={user.avatar && user.avatar.url}
                    alt={user && user.name}
                    className="rounded-circle"
                  />
                </figure>
                <span>{user && user.name}</span>
              </Link>
              <div
                className="dropdown-menu"
                aria-labelledby="dropDownMenuButton"
              >
                {user && user.role === "admin" && (
                  <Link className="dropdown-item text-white" to="/dashboard">
                    Dashboard
                  </Link>
                )}
                <Link className="dropdown-item text-white" to="/orders/me">
                  Orders
                </Link>
                <Link className="dropdown-item text-white" to="/me">
                  Profile
                </Link>
                <Link
                  className="dropdown-item text-white"
                  to="/"
                  onClick={logoutHandler}
                >
                  Logout
                </Link>
              </div>
            </div>
          ) : (
            !loading && (
              <>
                <a
                  className="nav-link text-warning d-none d-sm-inline"
                  href="/login"
                >
                  Login
                </a>

                <span className="text-white my-auto d-none d-sm-inline">|</span>

                <a
                  className="nav-link text-warning d-none d-sm-inline"
                  href="/register"
                >
                  Register
                </a>
              </>
            )
          )}

          <hr className="text-white" />
          <ul className="list-unstyled">
            <li>
              <Link to="/">
                <FontAwesomeIcon icon={faHome} /> Home
              </Link>
            </li>
            <li>
              <Link to="/dashboard">
                <FontAwesomeIcon icon={faTachometer} /> Dashboard
              </Link>
            </li>

            <li>
              <a
                href="#productSubmenu"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <FontAwesomeIcon icon={faProductHunt} />
                Products
              </a>
              <ul className="collapse list-unstyled" id="productSubmenu">
                <li>
                  <Link to="/admin/products">
                    <FontAwesomeIcon icon={faClipboard} /> All
                  </Link>
                </li>

                <li>
                  <Link to="/admin/product">
                    <FontAwesomeIcon icon={faPlus} /> Create
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/admin/orders">
                <FontAwesomeIcon icon={faShoppingBasket} /> Orders
              </Link>
            </li>

            <li>
              <Link to="/admin/users">
                <FontAwesomeIcon icon={faUsers} /> Users
              </Link>
            </li>

            <li>
              <Link to="/admin/discountitems">
                <FontAwesomeIcon icon={faPercent} /> Offer Items
              </Link>
            </li>

            <li>
              <Link to="/admin/reviews">
                <FontAwesomeIcon icon={faStar} /> Reviews
              </Link>
            </li>

            <li>
              <Link to="/admin/outofstock">
                <FontAwesomeIcon icon={faExclamationCircle} /> Stock Out
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
