import React, { useEffect ,useRef} from "react";
import MetaData from "../layouts/MetaData";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { createOrder, clearErrors } from "../../actions/orderAction";
import CheckoutSteps from "./CheckoutSteps";
import axios from "axios";
import { clearCart } from "../../actions/cartAction";

const options = {
  style: {
    base: {
      fontSize: "16px",
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const COD = ({ history }) => {
  const alert = useAlert();

  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { cartItems, shippingInfo } = useSelector((state) => state.cart);
  const { error } = useSelector((state) => state.newOrder);

  const codCardRef = useRef(null); // Step 2

  useEffect(() => {
    if (codCardRef.current) {
      // Step 4
      codCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors);
    }
  }, [dispatch, alert, error]);

  // create order to object to pass for payment
  const order = {
    orderItems: cartItems,
    shippingInfo,
  };

  // Bringing order info from session storage to proceed payment
  const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));
  if (orderInfo) {
    order.itemsPrice = orderInfo.itemsPrice;
    order.shippingPrice = orderInfo.shippingPrice;
    order.taxPrice = orderInfo.taxPrice;
    order.totalPrice = orderInfo.totalPrice;
  }

  // pass the amount that costumer has to pay
  const paymentData = {
    amount: Math.round(orderInfo.totalPrice * 100), //The amount is calculated in paisa. So in order to make the amount in rupees we multiply by 100
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    document.querySelector("#pay_btn").disabled = true;
    // let res;
    try {
      // const config = {
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      // };
      // res = await axios.post(
      //   "/api/v1/payment/process",
      //   paymentData,
      //   order,
      //   config
      // );
      if (error) {
        alert.error("There is some issue during the payment process");
      } else {
        dispatch(createOrder(order));
        // dispatch(clearCart(cartItems));
        history.push("/success");
      }
    } catch (error) {
      document.querySelector("#pay_btn").disabled = false;
      alert.error(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  return (
    <>
      <MetaData title={"Payments"} />
      <div className="row wrapper" ref={codCardRef}>
        <div className="col-10 col-lg-5" >
          <form onSubmit={handleSubmit} className="shadow-lg">
            <h1 className="mb-4">I Will Pay in Cash</h1>
            <button id="pay_btn" type="submit" className="btn btn-block py-3" >
              Pay {`AED ${orderInfo && orderInfo.totalPrice} ?`} on delivery
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default COD;
