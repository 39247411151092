import {
  ADMIN_PRODUCTS_REQUEST,
  ADMIN_PRODUCTS_SUCCESS,
  ADMIN_PRODUCTS_FAIL,
  ALL_PRODUCTS_REQUEST,
  ALL_PRODUCTS_SUCCESS,
  ALL_PRODUCTS_FAIL,
  ALL_DISCOUNT_PRODUCTS_REQUEST,
  ALL_DISCOUNT_PRODUCTS_SUCCESS,
  ALL_DISCOUNT_PRODUCTS_FAIL,
  PRODUCTS_WITHOUT_DISCOUNT_REQUEST,
  PRODUCTS_WITHOUT_DISCOUNT_SUCCESS,
  PRODUCTS_WITHOUT_DISCOUNT_FAIL,
  DISPLAY_IN_SAVE_YOUR_BUY_REQUEST,
  DISPLAY_IN_SAVE_YOUR_BUY_SUCCESS,
  DISPLAY_IN_SAVE_YOUR_BUY_FAIL,
  PRODUCTS_BY_CATEGORY_REQUEST,
  PRODUCTS_BY_CATEGORY_SUCCESS,
  PRODUCTS_BY_CATEGORY_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_RESET,
  NEW_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_RESET,
  DELETE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_RESET,
  PRODUCT_REVIEW_REQUEST,
  PRODUCT_REVIEW_SUCCESS,
  PRODUCT_REVIEW_RESET,
  PRODUCT_REVIEW_FAIL,
  GET_REVIEWS_REQUEST,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAIL,
  DELETE_REVIEWS_REQUEST,
  DELETE_REVIEWS_SUCCESS,
  DELETE_REVIEWS_RESET,
  DELETE_REVIEWS_FAIL,
  CLEAR_ERRORS,
} from "../constants/productConstants";

const initialState = {
  products: [],
  displayInSaveYourBuy:[],
  loading: false,
  error: null,
 
};


export const productsReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case ALL_PRODUCTS_REQUEST:
    case ADMIN_PRODUCTS_REQUEST:
      return {
        loading: true,
        products: [],
      };

    case ALL_PRODUCTS_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        productsCount: action.payload.productsCount,
        discountProductsCount:action.payload.discountProductsCount,
        resPerPage: action.payload.resPerPage,
        filteredProductsCount: action.payload.filteredProductsCount,
        // displayInSaveYourBuy:action.payload.displayInSaveYourBuy,
      };

    case ADMIN_PRODUCTS_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };

    case ALL_PRODUCTS_FAIL:
    case ADMIN_PRODUCTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const discountProductsReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case ALL_DISCOUNT_PRODUCTS_REQUEST:
    // case ADMIN_PRODUCTS_REQUEST:
      return {
        loading: true,
        products: [],
      };

    case ALL_DISCOUNT_PRODUCTS_SUCCESS:
      return {
        loading: false,
        discountProducts: action.payload.products,
        // productsCount: action.payload.productsCount,
        discountProductsCount:action.payload.discountProductsCount,
        resPerPage: action.payload.resPerPage,
        filteredProductsCount: action.payload.filteredProductsCount,
        // displayInSaveYourBuy:action.payload.displayInSaveYourBuy,
      };

    // case ADMIN_PRODUCTS_SUCCESS:
    //   return {
    //     loading: false,
    //     products: action.payload,
    //   };

    case ALL_DISCOUNT_PRODUCTS_FAIL:
    // case ADMIN_PRODUCTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const displayInSaveYourBuyReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case DISPLAY_IN_SAVE_YOUR_BUY_REQUEST:
    
      return {
        loading: true,
        products: [],
      };

    case DISPLAY_IN_SAVE_YOUR_BUY_SUCCESS:
      return {
        loading: false,
        displayInSaveYourBuy: action.payload.products,
        productsCount: action.payload.productsCount,
        filteredProductsCount: action.payload.filteredProductsCount,
      };

    case DISPLAY_IN_SAVE_YOUR_BUY_FAIL:
  
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};



export const productsWithoutDiscountReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCTS_WITHOUT_DISCOUNT_REQUEST:
    // case ADMIN_PRODUCTS_REQUEST:
      return {
        loading: true,
        products: [],
      };

    case PRODUCTS_WITHOUT_DISCOUNT_SUCCESS:
      return {
        loading: false,
        productsWithoutDiscount: action.payload.products,
        productsCount: action.payload.productsCount,
        // discountProductsCount:action.payload.discountProductsCount,
        resPerPage: action.payload.resPerPage,
        filteredProductsCount: action.payload.filteredProductsCount,
        // displayInSaveYourBuy:action.payload.displayInSaveYourBuy,
      };

    // case ADMIN_PRODUCTS_SUCCESS:
    //   return {
    //     loading: false,
    //     products: action.payload,
    //   };

    case PRODUCTS_WITHOUT_DISCOUNT_FAIL:
    // case ADMIN_PRODUCTS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};


export const productsByCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PRODUCTS_BY_CATEGORY_REQUEST':
      return {
        ...state,
        loading: true,
      };    
    case 'PRODUCTS_BY_CATEGORY_SUCCESS':
      return {
        ...state,
        loading: false,
        products: action.payload.products,
      };
    case 'PRODUCTS_BY_CATEGORY_FAIL':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};



export const productDetailsReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PRODUCT_DETAILS_SUCCESS:
      return {
        loading: false,
        product: action.payload,
      };

    case PRODUCT_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const productReviewReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case PRODUCT_REVIEW_SUCCESS:
      return {
        loading: false,
        success: action.payload,
      };

    case PRODUCT_REVIEW_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case PRODUCT_REVIEW_RESET:
      return { ...state, success: false };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const productReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRODUCT_REQUEST:
    case UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_PRODUCT_FAIL:
    case UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DELETE_PRODUCT_RESET:
      return { ...state, isDeleted: false };

    case UPDATE_PRODUCT_RESET:
      return { 
        ...state,
        isUpdated: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const newProductReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case NEW_PRODUCT_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        product: action.payload.product,
      };

    case NEW_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case NEW_PRODUCT_RESET:
      return { ...state, idDeleted: false };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};


export const productReviewsReducer = (state = {reviews:[]}, action) => {
  switch (action.type) {
    case GET_REVIEWS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_REVIEWS_SUCCESS:
      return {
        loading: false,
        reviews: action.payload,
      };

    case GET_REVIEWS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const deleteReviewsReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_REVIEWS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELETE_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

      case DELETE_REVIEWS_RESET:
        return{
          ...state,
          isDeleted:false
        }

    case DELETE_REVIEWS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};