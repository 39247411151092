import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, newProduct } from "../../actions/productAction";
import { NEW_PRODUCT_RESET } from "../../constants/productConstants";
import MetaData from "../layouts/MetaData";
import Sidebar from "./Sidebar";

const NewProduct = ({ history }) => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [size, setSize] = useState("NA");
  const [color, setColor] = useState("NA");
  const [stock, setStock] = useState(0);
  const [seller, setSeller] = useState("Artah");
  const [thumbnails, setThumbnails] = useState([]);
  const [thumbnailsPreview, setThumbnailsPreview] = useState([]);
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [displayInSaveYourBuy, setDisplayInSaveYourBuy] = useState(false);

  const categories = [
    "Select Category",
    "Men Garments",
    "Women Garments",
    "Children Garments",
    "Men Footwear",
    "Women Footwear",
    "Children Footwear",
    "Personal Care",
    "MotherNBabyCare",
    "MakeupNCosmetics",
    "Home Linens",
    "Gift Items",
    "Imitation Jewellery",
  ];

  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector((state) => state.newProduct);
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      history.push("/admin/products");
      // history.push(`/products?category=${category}`)
      alert.success("New product created successfully");
      dispatch({
        type: NEW_PRODUCT_RESET,
      });
    }
  }, [dispatch, error, success, history, alert]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set("name", name);
    formData.set("price", price);
    formData.set("discount", discount);
    formData.set("displayInSaveYourBuy", displayInSaveYourBuy);
    formData.set("description", description);
    formData.set("category", category);
    formData.set("size", size);
    formData.set("color", color);
    formData.set("stock", stock);
    formData.set("seller", seller);
    thumbnails.forEach((thumbnail) => {
      formData.append("thumbnails", thumbnail);
    });
    images.forEach((image) => {
      formData.append("images", image);
    });
    dispatch(newProduct(formData));
  };

  const onChange = (e) => {
    const files = Array.from(e.target.files);
    setImagesPreview([]);
    setImages([]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImagesPreview((oldArray) => [...oldArray, reader.result]);
          setImages((oldArray) => [...oldArray, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleThumbnail = (e) => {
    const files = Array.from(e.target.files);
    setThumbnailsPreview([]);
    setThumbnails([]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setThumbnailsPreview((oldArray) => [...oldArray, reader.result]);
          setThumbnails((oldArray) => [...oldArray, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // const handleSizeChange = (event) => {
  //   const { value } = event.target;
  //   const index = sizes.indexOf(value);

  //   if (index > -1) {
  //     setSizes([...sizes.slice(0, index), ...sizes.slice(index + 1)]);
  //   } else {
  //     setSizes([...sizes, value]);
  //   }
  // };

  // const handleColorChange = (event) => {
  //   const { value } = event.target;
  //   const index = colors.indexOf(value);

  //   if (index > -1) {
  //     setColors([...colors.slice(0, index), ...colors.slice(index + 1)]);
  //   } else {
  //     setColors([...colors, value]);
  //   }
  // };

  // const handleColorChange = (event) => {
  //   const color = event.target.value;
  //   if (color && !colors.includes(color)) {
  //     setColors([...colors, color]);
  //   }
  // };

  // const handleColorsChange = (event) => {
  //   const value = event.target.value;
  //   const colorsArray = value.split(",").map((color) => color.trim());
  //   setColors(colorsArray);
  // };

  return (
    <>
      <MetaData title={"Add New Product"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        <div className="col-12 col-md-10">
          <>
            <div className="my-5">
              <form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                className="form-card"
              >
                <h3 className="text-center mb-4">Add a New Product</h3>

                <div className="row mx-5">
                  <div className="mx-auto form-group col-sm-6 flex-column d-flex">
                    <label htmlFor="name_field">Name</label>
                    <input
                      type="text"
                      id="name_field"
                      className="form-control"
                      placeholder="Product Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <div className="mx-auto form-group col-sm-6 flex-column d-flex">
                    <label htmlFor="category_field  ">Category</label>
                    <select
                      className="form-control"
                      id="category_field"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mx-5">
                  <div className="mx-auto form-group col-sm-6 flex-column d-flex">
                    <label htmlFor="price_field">Price</label>
                    <input
                      type="text"
                      id="price_field"
                      className="form-control"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>

                  <div className="mx-auto form-group col-sm-6 flex-column d-flex">
                    <label htmlFor="discount percent">
                      Add Discount percent
                    </label>
                    <div className="d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="mr-3"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />

                      <input
                        type="text"
                        className="form-control"
                        disabled={!isChecked}
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mx-5">
                  <div className="mx-auto form-group col-sm-6 flex-column d-flex">
                    <label htmlFor="name_field">Size</label>
                    <input
                      type="text"
                      id="name_field"
                      className="form-control"
                      value={size}
                      onChange={(e) => setSize(e.target.value)}
                    />
                  </div>

                  <div className="mx-auto form-group col-sm-6 flex-column d-flex">
                    <label htmlFor="name_field">Color</label>
                    <input
                      type="text"
                      id="name_field"
                      className="form-control"
                      value={color}
                      onChange={(e) => setColor(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row mx-5">
                  <div className="mx-auto form-group col-sm-6 flex-column d-flex">
                    <label htmlFor="stock_field">Stock</label>
                    <input
                      type="number"
                      id="stock_field"
                      className="form-control"
                      value={stock}
                      onChange={(e) => setStock(e.target.value)}
                    />
                  </div>

                  <div className="mx-auto form-group col-sm-6 flex-column d-flex">
                    <label htmlFor="seller_field">Seller Name</label>
                    <input
                      type="text"
                      id="seller_field"
                      className="form-control"
                      value={seller}
                      onChange={(e) => setSeller(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mx-5 form-group col-sm-10 flex-column d-flex">
                  <label htmlFor="description_field">Description</label>
                  <textarea
                    className="form-control"
                    style={{ whiteSpace: "pre-wrap" }}
                    id="description_field"
                    rows="8"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>

                <div className="row mx-5">
                  <div className="mx-auto form-group col-sm-6 flex-column d-flex">
                    <label>Thumbnails</label>

                    <div className="custom-file">
                      <input
                        type="file"
                        name="product_images"
                        className="custom-file-input"
                        id="customFile"
                        onChange={handleThumbnail}
                        multiple
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        Choose Images
                      </label>
                    </div>
                    {thumbnailsPreview.map((thumbnail) => (
                      <img
                        src={thumbnail}
                        key={thumbnail}
                        alt="Images Preview"
                        width="55"
                        height="52"
                        className="mt-3 mr-2"
                      />
                    ))}
                  </div>

                  <div className="mx-auto form-group col-sm-6">
                    <label>Images</label>

                    <div className="custom-file">
                      <input
                        type="file"
                        name="product_images"
                        className="custom-file-input"
                        id="customFile"
                        onChange={onChange}
                        multiple
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        Choose Images
                      </label>
                    </div>
                    {imagesPreview.map((img) => (
                      <img
                        src={img}
                        key={img}
                        alt="Images Preview"
                        width="55"
                        height="52"
                        className="mt-3 mr-2"
                      />
                    ))}
                  </div>
                </div>
                <div className="mx-5 form-group col-sm-6 flex-column d-flex">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="displayInSaveYourBuy"
                      checked={displayInSaveYourBuy}
                      onChange={() =>
                        setDisplayInSaveYourBuy(!displayInSaveYourBuy)
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="displayInSaveYourBuy"
                    >
                      Display in Save Your Buy
                    </label>
                  </div>
                </div>

                <div className="mx-5 d-flex form-group float-right">
                  <button
                    id="login_button"
                    type="submit"
                    className="btn btn-block artah-button py-3 px-5"
                    disabled={loading ? true : false}
                  >
                    {loading ? "Loading..." : "CREATE"}
                  </button>
                </div>
              </form>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default NewProduct;
