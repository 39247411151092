import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const Product = ({ product, col, currentPage }) => {
  const discountPrice = product.price * (1 - product.discount / 100).toFixed(2);

  return (
    <div className={`col-6 col-sm-6 col-md-4 col-lg-${col} my-3`}>
      <div className="card border-0">
        {product.thumbnails[0] && (
          <img
            className="card-img-top img-fluid px-1 py-1"
            src={product.thumbnails[0].url}
            alt={product.name}
          />
        )}
        {product.discount ? (
          <div className="sale-badge">
            <span className="sale-text">{product.discount} % off</span>
          </div>
        ) : (
          <></>
        )}

        <div className="card-body d-flex flex-column">
          <h5 className="card-title mx-2">
            <h5>
              <Link to={`product/${product._id}`}>{product.name}</Link>
            </h5>
          </h5>
          <div className="ratings mt-auto mx-2">
            <div className="rating-outer">
              <div
                className="rating-inner"
                style={{ width: `${(product.ratings / 5) * 100}%` }}
              ></div>
            </div>
            <span id="no_of_reviews">{product.numOfReviews} reviews</span>
          </div>
          {product.discount ? (
            <div className="d-flex mx-2">
              <p className="card-text">
                AED <span className="strike">{product.price}</span>
              </p>
              <p className="card-text mx-auto">{discountPrice.toFixed(2)}</p>
            </div>
          ) : (
            <p className="card-text mx-2">AED {product.price}</p>
          )}

          <Link
            to={{
              pathname: `product/${product._id}`,
              search: `?page=${currentPage}`,
              state: { scrollToDetails: true },
            }}
            id="view_btn"
            className="btn text-nowrap mx-auto"
          >
            View Details
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Product;
