import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDisplayInSaveYourBuy } from "../../actions/productAction";
import Product from "../products/Product";
import { useAlert } from "react-alert";
import "rc-slider/assets/index.css";
import { Link } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Fade, Bounce, Zoom } from "react-reveal";

const SaveYourBuy = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.products);

  const { displayInSaveYourBuy } = useSelector(
    (state) => state.displayInSaveYourBuy
  );

  useEffect(() => {
    if (error) {
      return alert.error(error);
    }
    dispatch(getDisplayInSaveYourBuy());
  }, [dispatch, alert, error]);

  return (
    <div className="bg-light col-md-12">
      <div className="container">
        <div className="row mt-5">
          <h2 className="float-left ml-2 artah-text-purple">
            Save Your Buy{" "}
          </h2>
          <p className="star-badge blink text-white px-2 py-1 artah-orange mt-2">
            upto 30%
          </p>
        </div>

        <div>
          <Fade duration={2000}>
            <div className="d-flex row">
              {displayInSaveYourBuy &&
                displayInSaveYourBuy
                  .slice(0, 8)
                  .map((product) => (
                    <Product key={product._id} product={product} col={3} />
                  ))}
            </div>
          </Fade>
        </div>

        <div className="d-flex mx-auto justify-content-center my-3">
          <Bounce duration={3000}>
            <Link
              to="/discountproducts"
              className="btn border artah-purple px-5 mx-auto text-white"
            >
              View All
            </Link>
          </Bounce>
        </div>
      </div>
    </div>
  );
};

export default SaveYourBuy;
