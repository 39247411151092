import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faMoneyBillWave,
  faCheckCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Fade, Bounce, Zoom, Slide } from "react-reveal";

const ShopOnlineInUAE = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 text-center">
          <Zoom>
            <h2 className="mt-5 artah-text-purple">Shop Online in UAE</h2>
          </Zoom>
          <ul className="list-group list-group-flush mt-5 no-bullets">
            <Slide left>
              <li
                className="list-item artah-text-purple"
                style={{ fontFamily: "Arvo" }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="mr-4 artah-text-yellow "
                />
                Find Variety of Products
              </li>
            </Slide>

            <Slide left>
              <li
                className="list-group-item-flush artah-text-purple mt-3"
                style={{ fontFamily: "Arvo" }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="mr-4 artah-text-yellow"
                />
                Explore for Great Deals
              </li>
            </Slide>

            <Slide left>
              <li
                className="list-group-item-flush artah-text-purple mt-3"
                style={{ fontFamily: "Arvo" }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="mr-4 artah-text-yellow"
                />
                Shop in Affordable Prices
              </li>
            </Slide>
            <Slide left>
              <li
                className="list-group-item-flush artah-text-purple mt-3"
                style={{ fontFamily: "Arvo" }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="mr-4 artah-text-yellow"
                />
                Buy Your Best Products
              </li>
            </Slide>

            <Slide left>
              <li
                className="list-group-item-flush artah-text-purple mt-3"
                style={{ fontFamily: "Arvo" }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className="mr-4 artah-text-yellow"
                />
                Work-Earn-Buy-Repeat
              </li>
            </Slide>
          </ul>

          <Slide up>
            <Link
              to="/allproducts"
              className="btn artah-btn-gradient text-white my-5 px-5 py-2 rounded"
            >
              Get Started
            </Link>
          </Slide>
        </div>

        <div class="col-md-6 text-center my-auto">
          <img
            src="images/Carousel1.png"
            alt="SummerSale1"
            className="img-fluid mr-2 animated"
          />
        </div>
      </div>
    </div>
  );
};

export default ShopOnlineInUAE;
