import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import MetaData from "../layouts/MetaData";
import CheckoutSteps from "./CheckoutSteps";

const ConfirmOrder = ({ history }) => {
  const { user } = useSelector((state) => state.auth);
  const { cartItems, shippingInfo } = useSelector((state) => state.cart);

  //   Calculate Order Prices
  const itemsPrice = cartItems.reduce(
    (acc, item) => acc + (item.price * (1-item.discount/100)) * item.quantity,
    0
  );
  const shippingPrice = itemsPrice > 150 ? 0 : 15;
  // const taxPrice = Number((0.05 * itemsPrice).toFixed(2));
  const totalPrice = (itemsPrice + shippingPrice).toFixed(2);

  const proceedToPayment = () => {
    const data = {
      itemsPrice: itemsPrice.toFixed(2),
      shippingPrice,
      // taxPrice,
      totalPrice,
    };
    sessionStorage.setItem("orderInfo", JSON.stringify(data));
    history.push("/cod");
  };

  return (
    <>
      <MetaData title={"Confirm Order"} />
      <CheckoutSteps shipping confirmOrder />
      <div className="row d-flex justify-content-between">
        <div className="col-12 col-lg-8 mt-5 order-confirm">
          <h4 className="mb-3">Shipping Info</h4>
          <p>
            <b>Name:</b>
            {user && user.name}
          </p>
          <p>
            <b>Phone:</b>
            {shippingInfo.phoneNo}
          </p>
          <p className="mb-4">
            <b>Local Address:</b>
            {shippingInfo && `${shippingInfo.localAddress}, ${shippingInfo.city}, ${shippingInfo.country}`}
          </p>

          <hr />
          <h4 className="mt-4">Your Cart Items:</h4>
          {cartItems.map((item) => (
            <>
              <hr />
              <div className="cart-item my-1" key={item.product}>
                <div className="row" >
                  <div className="col-4 col-lg-2">
                    <img
                      src={item.image}
                      alt={item.name}
                      height="45"
                      width="65"
                    />
                  </div>

                  <div className="col-4 col-lg-6">
                    <Link to={`/product/${item.product}`}>{item.name}</Link>
                  </div>

                  <div className ="col-4 col-lg-6">
                  <p>Size: 
                          {item.size ? ` ${item.size}`:" NA"}
                          </p>
                          <p>
                            Color:{item.color ?` ${item.color}`: " NA"}
                          </p>
                  </div>

                  <div className="col-4 col-lg-4 mt-4 mt-lg-0">
                    <p>
                      {item.quantity} x {(item.price * (1-item.discount/100)).toFixed(2)} =
                      {`AED`}{(item.quantity * item.price* (1-item.discount/100)).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
            </>
          ))}
        </div>

        <div className="col-12 col-lg-3 my-4">
          <div id="order_summary">
            <h4>Order Summary</h4>
            <hr />
            <p>
              Subtotal:{" "}
              <span className="order-summary-values">{`AED`}{itemsPrice.toFixed(2)}</span>
            </p>
            <p>
              Shipping:{" "}
              <span className="order-summary-values">{`AED`}{shippingPrice.toFixed(2)}</span>
            </p>
            {/* <p>
              Tax: <span className="order-summary-values">{taxPrice}</span>
            </p> */}

            <hr />
            

            <p>
              Total:{" "}
              <span className="order-summary-values">{totalPrice}</span>
            </p>

            <hr />
            <p style={{fontSize:"12px"}}>Product price is inclusive of 5% VAT</p>
            <button
              id="checkout_btn"
              className="btn btn-primary btn-block"
              onClick={proceedToPayment}
              
            >
              Proceed to Payment
            </button>
            <br/>            
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmOrder;
