import React from "react";
import { Helmet } from "react-helmet";

const MetaData = ({ title, thumbnail }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      {thumbnail && <meta property="og:image" content={thumbnail} />}
      {/* Add other meta tags as needed */}
    </Helmet>
  );
};

export default MetaData;
