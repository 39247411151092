import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductDetails,
  getProductReviews,
  clearErrors,
} from "../../actions/productAction";
import Loader from "../layouts/Loader";
import MetaData from "../layouts/MetaData";
import { Carousel } from "react-bootstrap";
import { addItemsToCart } from "../../actions/cartAction";
import { PRODUCT_REVIEW_RESET } from "../../constants/productConstants";
import ListReviews from "../reviews/ListReviews";
import Modal from "react-modal";
import ReactImageMagnify from "react-image-magnify";
import { Helmet } from "react-helmet";
// import {
//   EmailIcon,
//   FacebookIcon,
//   FacebookMessengerShareButton,
//   FacebookMessengerIcon,

//   PinterestIcon,
//   RedditIcon,
//   TelegramIcon,
//   TwitterIcon,
//   ViberIcon,
//   WhatsappIcon,
//   ViberShareButton,
// } from "react-share";

const ProductDetails = ({ match, currentPage }) => {
  const [quantity, setQuantity] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
 

  const dispatch = useDispatch();
  const alert = useAlert();
  const { loading, error, product } = useSelector(
    (state) => state.productDetails
  );
  const { user } = useSelector((state) => state.auth);
  const { error: reviewError, success } = useSelector(
    (state) => state.productReview
  );
  const discountPrice = product.price * (1 - product.discount / 100);

  const location = useLocation();
  const productDetailsRef = useRef(null);
  const params = new URLSearchParams(location.search);
  const page = params.get("page");

  useEffect(() => {
    dispatch(getProductDetails(match.params.id));

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (reviewError) {
      alert.error(reviewError);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success("Review posted successfully");
      dispatch({ type: PRODUCT_REVIEW_RESET });
    }

    if (
      productDetailsRef.current &&
      location.state &&
      location.state.scrollToDetails
    ) {
      productDetailsRef.current.scrollIntoView({ behavior: "smooth" });
    }

    sessionStorage.setItem("productId", match.params.id);
    sessionStorage.setItem("currentPage", page); // Store the page number
  }, [
    dispatch,
    alert,
    error,
    reviewError,
    page,
    match.params.id,
    success,
    location.state,
  ]);

  useEffect(() => {
    // Set the URL of the first image as the selected image URL when the component mounts
    if (product.images && product.images.length > 0) {
      setSelectedImage(product.images[0].url);
    }
  }, [product.images]);

  


  const handleImageClick = (index) => {
    setSelectedImage(index); // Set the selected image index
  };

  const openModal = () => {
    console.log("Modal opened");
    setModalIsOpen(true);
  };

  // Adjust the debounce delay as needed

  const closeModal = () => {
    console.log("Modal closed");
    setModalIsOpen(false);
  };

  const increaseQty = () => {
    const count = document.querySelector(".count");
    if (count.valueAsNumber >= product.stock) {
      return;
    }
    const qty = count.valueAsNumber + 1;
    setQuantity(qty);
  };

  const decreaseQty = () => {
    const count = document.querySelector(".count");
    if (count.valueAsNumber <= 1) {
      return;
    }
    const qty = count.valueAsNumber - 1;
    setQuantity(qty);
  };

  const addToCart = () => {
    dispatch(addItemsToCart(match.params.id, quantity));
    alert.success("Item added to cart");
  };

  function setUserRatings() {
    const stars = document.querySelectorAll(".star");

    stars.forEach((star, index) => {
      star.starValue = index + 1;

      ["click", "mouseover", "mouseout"].forEach(function (e) {
        star.addEventListener(e, showRatings);
      });
    });

    function showRatings(e) {
      stars.forEach((star, index) => {
        if (e.type === "click") {
          if (index < this.starValue) {
            star.classList.add("orange");

            setRating(this.starValue);
          } else {
            star.classList.remove("orange");
          }
        }

        if (e.type === "mouseover") {
          if (index < this.starValue) {
            star.classList.add("yellow");
          } else {
            star.classList.remove("yellow");
          }
        }

        if (e.type === "mouseout") {
          star.classList.remove("yellow");
        }
      });
    }
  }

  const handleReview = () => {
    const formData = new FormData();
    formData.set("rating", rating);
    formData.set("comment", comment);
    formData.set("productId", match.params.id);

    dispatch(getProductReviews(formData));
  };

  const handleTouchStart = () => {
    openModal();
  };

  const handleTouchEnd = () => {
    closeModal();
  };

  // Destructure product data to access its properties
  // const { _id, name, description, thumbnails } = product;

  // Set the dynamic OG meta tags
  // const ogUrl = `https://yourdomain.com/product/${_id}`;
  // const ogImage =
  //   thumbnails.length > 0 ? thumbnails[0].url : "https://artahllc.com";

  // const shareUrl = `https://artahllc.com/product/${product._id}?page=${currentPage}`;
  // const ogImageUrl= product.thumbnails && product.thumbnails.length > 0 ? product.thumbnails[0].url : "https://artahllc.com";

  

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          
            
            <MetaData property="og:title" content={product.name} />
            <MetaData property="og:description" content={product.description} />
            
          

          <div className="container container-fluid">
            <div
              className="row f-flex justify-content-around"
              ref={productDetailsRef}
            >
              <div className="col-12 col-lg-5 " id="product_image">
                <div className="flex flex-column magnifier-container">
                  {selectedImage !== null && (
                    <div>
                      <ReactImageMagnify
                        {...{
                          smallImage: {
                            alt: product.title,
                            isFluidWidth: true,
                            src: selectedImage,
                          },
                          largeImage: {
                            src: selectedImage,
                            width: 1000, // Adjust the width of the zoomed image
                            height: 1200, // Adjust the height of the zoomed image
                          },
                          lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
                          enlargedImageContainerStyle: { zIndex: 999 },
                          shouldUsePositiveSpaceLens: true,
                        }}
                      />
                    </div>
                  )}

                  <div
                    className="row mt-5"
                    onTouchStart={handleTouchStart} // Trigger openModal on touch start
                    onTouchEnd={handleTouchEnd} // Trigger closeModal on touch end
                  >
                    {product.images &&
                      product.images.map((image, index) => (
                        <div
                          key={image.public_id}
                          className="col-3 mb-3"
                          onMouseEnter={() => handleImageClick(image.url)}
                          onClick={() => handleImageClick(image.url)} // Call the click handler on image click
                        >
                          <img
                            className="d-block img-fluid zoom-image img-border"
                            src={image.url}
                            alt={product.title}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-5 mt-5">
                <h3>{product.name}</h3>
                <p id="product_id">Product# {product._id}</p>
                <hr />
                <div className="ratings mt-auto">
                  <div className="rating-outer">
                    <div
                      className="rating-inner"
                      style={{ width: `${(product.ratings / 5) * 100}%` }}
                    ></div>
                  </div>
                  <span id="no_of_reviews">{product.numOfReviews} reviews</span>
                </div>
                <hr />
                <p id="product_price">AED {discountPrice.toFixed(2)}</p>
                <div className="stockCounter d-inline">
                  <span className="btn btn-danger minus" onClick={decreaseQty}>
                    -
                  </span>

                  <input
                    type="number"
                    className="form-control count d-inline"
                    value={quantity}
                    readOnly
                  />

                  <span className="btn btn-primary plus" onClick={increaseQty}>
                    +
                  </span>
                </div>
                <button
                  type="button"
                  id="cart_btn"
                  className="btn btn-primary d-inline ml-4"
                  onClick={addToCart}
                  disabled={product.stock <= 0}
                >
                  Add to Cart
                </button>
                <hr />
                <p>
                  Status:{" "}
                  <span
                    id="stock_status"
                    className={product.stock > 0 ? "greenColor" : "redColor"}
                  >
                    {product.stock > 0 ? "In Stock" : "Out of Stock"}
                  </span>
                </p>
                <hr />
                <p className="mt-2">
                  Category:{" "}
                  <span className="stock_status">{product.category}</span>
                </p>
                <hr />
                <p className="mt-2">
                  Size: <span className="stock_status">{product.size}</span>
                </p>
                <hr />
                <p className="mt-2">
                  Color: <span className="stock_status">{product.color}</span>
                </p>{" "}
                <hr />
                <h4 className="mt-2">Description:</h4>
                <p style={{ whiteSpace: "pre-wrap" }}>{product.description}</p>
                <hr />
                <p id="product_seller mb-3">
                  Sold by: <strong>{product.seller}</strong>
                </p>
                {user && user ? (
                  <button
                    id="review_btn"
                    type="button"
                    className="btn btn-primary mt-4"
                    data-toggle="modal"
                    data-target="#ratingModal"
                    onClick={setUserRatings}
                  >
                    Submit Your Review
                  </button>
                ) : (
                  <div className="alert alert-danger mt-5" type="alert">
                    Login to post your review
                  </div>
                )}
                {/* <div className="row mx-auto">
                <FacebookIcon size={32} round={true} url={shareUrl} className="mx-1"/>
                <FacebookMessengerShareButton redirectUri={shareUrl}>
                <FacebookMessengerIcon size={32} round={true} className="mx-1"/>
                </FacebookMessengerShareButton>
                <WhatsappIcon size={32} round={true}  className="mx-1"/>
                <TwitterIcon size={32} round={true}  className="mx-1"/>
                <ViberShareButton url={shareUrl}>
                <ViberIcon size={32} round={true}  className="mx-1"/>
                </ViberShareButton>
                </div> */}
                <div className="row mt-2 mb-5">
                  <div className="rating w-50">
                    <div
                      className="modal fade"
                      id="ratingModal"
                      tabIndex="-1"
                      role="dialog"
                      aria-labelledby="ratingModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="ratingModalLabel">
                              Submit Review
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <ul className="stars">
                              <li className="star">
                                <i className="fa fa-star"></i>
                              </li>
                              <li className="star">
                                <i className="fa fa-star"></i>
                              </li>
                              <li className="star">
                                <i className="fa fa-star"></i>
                              </li>
                              <li className="star">
                                <i className="fa fa-star"></i>
                              </li>
                              <li className="star">
                                <i className="fa fa-star"></i>
                              </li>
                            </ul>

                            <textarea
                              name="review"
                              id="review"
                              className="form-control mt-3"
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            ></textarea>

                            <button
                              className="btn my-3 float-right review-btn px-4 text-white"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={handleReview}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {product.reviews && product.reviews.length > 0 && (
            <ListReviews reviews={product.reviews} />
          )}
        </>
      )}
    </>
  );
};

export default ProductDetails;
