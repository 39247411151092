import React, { useState } from "react";

const Search = ({ history }) => {
  const [keyword, setKeyword] = useState();

  const handleSearch = (event) => {
    event.preventDefault();
    if (keyword.trim()) {
      history.push(`/search/${keyword}`);
    } else {
      history.push("/");
    }
  };
  return (
    <div>
      <form>
        <div className="input-group">
          <input
            type="text"
            id="search_field"
            className="form-control"
            placeholder="Enter Product Name ..."
            onChange={(event) => setKeyword(event.target.value)}
          />
          <div className="input-group-append">
            <button onClick={handleSearch} id="search_btn" className="btn">
              <i className="fa fa-search text-white" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Search;
