import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MetaData";
import Loader from "../layouts/Loader";
import Sidebar from "./Sidebar";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  orderDetails,
  updateOrder,
  clearErrors,
} from "../../actions/orderAction";
import { UPDATE_ORDER_RESET } from "../../constants/orderConstants";

const ProcessOrder = ({ match }) => {
  const [status, setStatus] = useState("");

  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, order = {} } = useSelector((state) => state.orderDetails);
  const { product } = useSelector((state) => state.productDetails);
  const {
    shippingInfo,
    orderItems,
    paymentInfo,
    user,
    totalPrice,
    orderStatus,
  } = order;

  const { error, isUpdated } = useSelector((state) => state.order);
  const orderId = match.params.id;

  useEffect(() => {
    dispatch(orderDetails(orderId));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Order updated successfully");
      dispatch({ type: UPDATE_ORDER_RESET });
    }
  }, [dispatch, alert, error, isUpdated, orderId]);

  const handleSubmit = (id) => {
    const formData = new FormData();
    formData.set("status", status);

    dispatch(updateOrder(id, formData));
  };

  const shippingDetails =
    shippingInfo &&
    `${shippingInfo.localAddress}, ${shippingInfo.city}, ${shippingInfo.country}`;

  const isPaid =
    order.orderStatus && String(order.orderStatus).includes("Delivered")
      ? true
      : false;
  return (
    <>
      <MetaData title={`Process Order # ${order && order._id}`} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        <div className="col-12 col-md-10">
          <>
            {loading ? (
              <Loader />
            ) : (
              <>
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-7 col-md-12">
                      <h4 className="my-5">Order # {order._id}</h4>

                      <h4 className="mb-4">Shipping Info</h4>
                      <p>
                        <b>Name:</b> {user && user.name}
                      </p>
                      <p>
                        <b>Email:</b> {user && user.email}
                      </p>
                      <p>
                        <b>Phone:</b> {shippingInfo && shippingInfo.phoneNo}
                      </p>
                      <p className="mb-4">
                        <b>Address:</b> {shippingDetails}
                      </p>
                      <p>
                        <b>Amount:</b> {"AED"} {totalPrice}
                      </p>

                      <hr />

                      <h4 className="my-4">Payment</h4>
                      <p className={isPaid ? "greenColor" : "redColor"}>
                        <b>{isPaid ? "PAID" : "NOT PAID"}</b>
                      </p>

                      <h4 className="my-4">Order Status:</h4>
                      <p
                        className={
                          order.orderStatus &&
                          String(order.orderStatus).includes("Delivered")
                            ? "greenColor"
                            : "redColor"
                        }
                      >
                        <b>{orderStatus}</b>
                      </p>

                      <h4 className="my-4">Delivered On:</h4>
                      {order.orderStatus === "Delivered" ? (
                        <p>{String(order.deliveredAt).substring(0, 10)}</p>
                      ) : (
                        <p>Not Delivered</p>
                      )}

                      <h4 className="my-4">Order Items:</h4>

                      <hr />
                      <div className="cart-item my-1">
                        {orderItems &&
                          orderItems.map((item) => (
                            <div key={item.product} className="row my-5">
                              <div className="col-12 col-lg-2">
                                <img
                                  src={item.image}
                                  alt={item.name}
                                  height="45"
                                  width="65"
                                />
                              </div>

                              <div className="col-12 col-lg-5">
                                <Link to={`/product/${item.product}`}>
                                 <b> {item.name}</b>
                                </Link>
                                <div className="mt-2">
                                <p>
                                  Size:
                                  {item.size ? ` ${item.size}` : " NA"}
                                </p>
                                <p>
                                  Color:{item.color ? ` ${item.color}` : " NA"}
                                </p>
                              </div>
                              </div>

                              <div className="col-12 col-lg-5">
                                <p>
                                  {`Product #`}
                                  {item.product}
                                </p>
                              </div>

                              <div className="col-12 col-lg-2 mt-4 mt-lg-0">
                                <p>
                                  {`AED `}
                                  {item.price}
                                </p>
                              </div>

                              <div className="col-12 col-lg-3 mt-4 mt-lg-0">
                                <p>{item.quantity} Piece(s)</p>
                              </div>

                             
                            </div>
                          ))}
                      </div>
                      <hr />
                    </div>

                    <div className="col-12 col-lg-3 mt-5">
                      <h4 className="my-4">Status</h4>

                      <div className="form-group">
                        <select
                          className="form-control"
                          name="status"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <option value="Processing">Processing</option>
                          <option value="Shipped">Shipped</option>
                          <option value="Delivered">Delivered</option>
                        </select>
                      </div>

                      <button
                        id="cart_btn"
                        className="btn btn-primary btn-block"
                        onClick={() => handleSubmit(order._id)}
                      >
                        Update Status
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default ProcessOrder;
