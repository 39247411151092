import React from "react";
import Accordions from "../layouts/Accordions";

const FAQ = () => {
  return (
    <div className="container">
      <h2 className="text-dark my-5 display-5">FAQ</h2>

      <Accordions
        className="btn btn-link"
        id="faq-collapse-1"
        class="collapse show"
        headingId="faq-heading-1"
        dataTarget="#faq-collapse-1"
        ariaControls="faq-collapse-1"
        ariaLable="faq-heading-1"
        isExpanded="true"
        qsn="How do I place my order?"
        ans="You can place an order by adding items to your cart and checking
              out through our secure payment system."
      />

      <Accordions
        className="btn btn-link collapsed"
        id="faq-collapse-2"
        class="collapse"
        headingId="faq-heading-2"
        dataTarget="#faq-collapse-2"
        ariaControls="faq-collapse-2"
        ariaLable="faq-heading-2"
        isExpanded="false"
        qsn="What payment methods do you accept?"
        ans="As of now, we accept cash on delivery. We will be accepting major payment systems including credit cards in near future"
      />

      <Accordions
        className="btn btn-link collapsed"
        id="faq-collapse-3"
        class="collapse"
        headingId="faq-heading-3"
        dataTarget="#faq-collapse-3"
        ariaControls="faq-collapse-3"
        ariaLable="faq-heading-3"
        isExpanded="false"
        qsn="How long will it take to receive my order?"
        ans="Shipping times vary depending on your location and the shipping method you choose at checkout. You can check estimated delivery times during the checkout process.
          "
      />

      <Accordions
        className="btn btn-link collapsed"
        id="faq-collapse-4"
        class="collapse"
        headingId="faq-heading-3"
        dataTarget="#faq-collapse-4"
        ariaControls="faq-collapse-4"
        ariaLable="faq-heading-4"
        isExpanded="false"
        qsn="Can I track my order?"
        ans="Yes, you will receive a tracking number once your order has shipped. You can use this to track your package on our website or the carrier's website."
      />

      <Accordions
        className="btn btn-link collapsed"
        id="faq-collapse-5"
        class="collapse"
        headingId="faq-heading-5"
        dataTarget="#faq-collapse-5"
        ariaControls="faq-collapse-5"
        ariaLable="faq-heading-5"
        isExpanded="false"
        qsn="What is your return policy?"
        ans="We offer a 7-day return policy on most items. Please refer to our return policy page for more information."
      />

      <Accordions
        className="btn btn-link"
        id="faq-collapse-6"
        class="collapse"
        headingId="faq-heading-6"
        dataTarget="#faq-collapse-6"
        ariaControls="faq-collapse-6"
        ariaLable="faq-heading-6"
        isExpanded="false"
        qsn="How can I contact customer support?"
        ans="You can contact us through our website's Contact page or by emailing us at support@artahllc.com. We aim to respond to all inquiries within 24 hours."
      />

      <p className="my-3">
        {" "}
        If you have any other queries please don't hesitate to reach us
      </p>
    </div>
  );
};

export default FAQ;
