import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import Slider1 from "../images/Slider1.jpg";
import Slider2 from "../images/Slider2.jpg";
import Slider3 from "../images/Slider3.jpg";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faMoneyBillWave,
  faCheckCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Fade, Bounce, Zoom, Slide } from "react-reveal";

function MyCarousel() {
  const location = useLocation(); // Get the current location
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  // Check if the current location is the home page
  const isHomePage = location.pathname === "/";

  if (!isHomePage) {
    // Return null if not on the home page to hide the carousel
    return null;
  }

  return (
    <>
      <section className="slider">
        <Carousel controls={false} indicators={false} fade>
          {/* <Carousel.Item className="slide">
            <img
              className="d-block w-100 img-fluid carousel-img"
              src={Slider1}
              alt="First slide"
            />
          </Carousel.Item> */}

          <Carousel.Item className="slide">
            <img
              className="d-block w-100 img-fluid carousel-img"
              src={Slider2}
              alt="Second slide"
            />
          </Carousel.Item>

          <Carousel.Item className="slide">
            <img
              className="d-block w-100 img-fluid carousel-img"
              src={Slider3}
              alt="Third slide"
            />
          </Carousel.Item>
        </Carousel>
      </section>
    </>
  );
}

export default MyCarousel;
