import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productsReducer,
  discountProductsReducer,
  displayInSaveYourBuyReducer,
  productsWithoutDiscountReducer,
  productDetailsReducer,
  newProductReducer,
  productReviewReducer,
  productReducer,
  productReviewsReducer,
  deleteReviewsReducer,
  productsByCategoryReducer,
  
} from "./reducers/productsReducer";
import {
  authReducer,
  userReducer,
  googleLoginReducer,
  emailVerificationReducer,
  forgotPasswordReducer,
  allUsersReducer,
  userDetailsReducer,
} from "./reducers/usersReducer";
import { cartReducer } from "./reducers/cartsReducer";
import {
  newOrderReducer,
  myOrderReducer,
  orderDetailsReducer,
  allOrdersReducer,
  orderReducer,
} from "./reducers/ordersReducer";

const reducer = combineReducers({
  products: productsReducer,
  discountProducts:discountProductsReducer,
  displayInSaveYourBuy:displayInSaveYourBuyReducer,
  productsWithoutDiscount:productsWithoutDiscountReducer,
  productDetails: productDetailsReducer,
  auth: authReducer,
  user: userReducer,
  googleLogin:googleLoginReducer,
  verifyEmail:emailVerificationReducer,
  forgotPassword: forgotPasswordReducer,
  cart: cartReducer,
  newOrder: newOrderReducer,
  myOrders: myOrderReducer,
  orderDetails: orderDetailsReducer,
  productReview: productReviewReducer,
  newProduct: newProductReducer,
  productByCategory:productsByCategoryReducer,
  product: productReducer,
  allOrders: allOrdersReducer,
  order:orderReducer,
  allUsers: allUsersReducer,
  userDetails:userDetailsReducer,
  productReviews:productReviewsReducer,
  deleteReview:deleteReviewsReducer

});

const initialState = {
  cart: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],

    shippingInfo: localStorage.getItem("shippingInfo")
      ? JSON.parse(localStorage.getItem("shippingInfo"))
      : {},
  },

};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
