import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";

import MetaData from "../layouts/MetaData";
import Loader from "../layouts/Loader";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { orderDetails, clearErrors } from "../../actions/orderAction";

const OrderDetails = ({ match }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const {
    loading,
    error,
    order = {},
  } = useSelector((state) => state.orderDetails);
  const {
    shippingInfo,
    orderItems,
    paymentInfo,
    user,
    totalPrice,
    discount,
    orderStatus,
  } = order;

  useEffect(() => {
    dispatch(orderDetails(match.params.id));

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, alert, error, match.params.id]);

  const shippingDetails =
    shippingInfo &&
    `${shippingInfo.localAddress}, ${shippingInfo.city}, ${shippingInfo.country}`;

  const isPaid =
    order.orderStatus && String(order.orderStatus).includes("Delivered")
      ? true
      : false;

  return (
    <Fragment>
      <MetaData title={"Order Details"} />

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="container">
            <div className="row justify-content-between mt-2 order-details">
              <div className="col-12 col-lg-8">
                <h4 className="my-5">Order # {order._id}</h4>

                <h4 className="mb-4">Shipping Info</h4>
                <p>
                  <b>Name:</b> {user && user.name}
                </p>
                <p>
                  <b>Email:</b> {user && user.email}
                </p>
                <p>
                  <b>Phone:</b> {shippingInfo && shippingInfo.phoneNo}
                </p>
                <p className="mb-4">
                  <b>Address:</b> {shippingDetails}
                </p>
                <p>
                  <b>Amount:</b> {`AED`} {totalPrice}
                </p>

                <hr />

                
                <h4 className="my-4">Payment</h4>
                <p className={isPaid ? "greenColor" : "redColor"}>
                  <b>
                    {isPaid && orderStatus === "Delivered"
                      ? "PAID"
                      : "NOT PAID"}
                  </b>
                </p>

                <h4 className="my-4">Order Status:</h4>
                <p
                  className={
                    order.orderStatus &&
                    String(order.orderStatus).includes("Delivered")
                      ? "greenColor"
                      : "redColor"
                  }
                >
                  <b>{orderStatus}</b>
                </p>

                <h4 className="my-4">Delivered On:</h4>
                {order.orderStatus === "Delivered" ? (
                  <p>{String(order.deliveredAt).substring(0, 10)}</p>
                ) : (
                  <p>Not Delivered</p>
                )}

                <h4 className="my-4">Order Items:</h4>

                <hr />
                <div className="cart-item my-1 col-12 col-md-12">
                  {orderItems &&
                    orderItems.map((item) => (
                      <div key={item.product} className="my-5">
                        <div className="row">
                        <div className="col-12 col-md-4 col-lg-2">
                          <div>
                            <img
                              src={item.image}
                              alt={item.name}
                              height="45"
                              width="65"
                            />
                          </div>
                          <div className="mt-1">
                            <Link to={`/products/${item.product}`}>
                              {item.name}
                            </Link>
                          </div>
                        </div>

                        <div className="col-12 col-md-4 col-lg-2 mt-2">
                          <div>
                            <p>
                              {`AED `}
                              {item.price}
                            </p>
                          </div>

                          <div className="mt-2">
                            <p>{item.quantity} Piece(s)</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 col-lg-2">
                        <div>
                          <p>Size: {item.size && item.size}</p>
                        </div>

                        <div>
                          <p>Color: {item.color && item.color}</p>
                        </div>
                        </div> 
                        </div>
                      </div>
                    ))}
                </div>
                <hr />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default OrderDetails;
