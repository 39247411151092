import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTruck,
  faMoneyBillWave,
  faCheckCircle,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Fade, Bounce, Slide } from "react-reveal";

const EcommAttributes = () => {
  return (
    <div className='col-md-12 text-center my-4 h-50 bg-light'>
           <section className="py-3 px-3 container">
            <Slide left>
              <div className="row">
                <div className="col-sm-6 col-md-3 text-center circle-icon artah-text-orange">
                  <FontAwesomeIcon icon={faTruck} className="my-2" />
                  <p>Free Delivery over AED 150</p>
                </div>
                <div className="col-sm-6 col-md-3 text-center circle-icon artah-text-orange">
                  <FontAwesomeIcon icon={faMoneyBillWave} className="my-2" />
                  <p>Cash On Delivery</p>
                </div>
                <div className="col-sm-6 col-md-3 text-center circle-icon artah-text-orange">
                  <FontAwesomeIcon icon={faCheckCircle} className="my-2" />
                  <p>Authentic Items</p>
                </div>
                <div className="col-sm-6 col-md-3 text-center circle-icon artah-text-orange">
                  <FontAwesomeIcon icon={faUndo} className="my-2" />
                  <p>Easy Returns</p>
                </div>
              </div>
            </Slide>
          </section>
    </div>
  )
}

export default EcommAttributes