import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { register, clearErrors } from "../../actions/userAction";
import MetaData from "../layouts/MetaData";


const Register = ({ history, location }) => {
  // const schema = yup.object().shape({
  //   title: yup.string().required("Title is required"),
  //   name: yup
  //     .string()
  //     .matches(/^([^0-9]*)$/, "Name should not contain numbers")
  //     .min(3)
  //     .required("Name is required"),
  //   email: yup
  //     .string()
  //     .email("Email should have correct format")
  //     .required("Email is required"),
  //   password: yup
  //     .string()
  //     .matches(
  //       /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).*$/,
  //       "Password must contain at least one uppercase letter, one digit, and one special character"
  //     )
  //     .min(8, "Password must be at least 8 characters")
  //     .required("Password is required"),
  // });
  const titles = ["", "Mr", "Mrs", "Ms"];
  const [user, setUser] = useState(
    {
      name: "",
      email: "",
      password: "",
    },
    
  );

  const { name, email, password } = user;
  const [title, setTitle] = useState("");
  const [avatar, setAvatar] = useState("");
  const [avatarPreview, setAvatarPreview] =  useState(
    "/images/default_avatar.png"
  );

  const alert = useAlert();
  const dispatch = useDispatch();
  const { isAuthenticated, error, loading } = useSelector(
    (state) => state.auth
  );
  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    // if (isAuthenticated) {
    //   history.push("/");
    // }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, alert, error]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.set("title", title);
    formData.set("name", name);
    formData.set("email", email);
    formData.set("password", password);
    formData.set("avatar", avatar);
    dispatch(register(formData))
      .then(() => {
        // Send verification email after successful registration
        // dispatch(sendVerificationEmail(email));
        alert.success(
          "A verification email has been sent. Please verify before login."
        );
        history.push(redirect);
      })
      .catch((error) => {
        alert.error(error);
      });
  };

  const handleChange = (e) => {
    if (e.target.name === "avatar") {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setAvatarPreview(reader.result);
          setAvatar(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setUser({ ...user, [e.target.name]: e.target.value });
    }
  };
  return (
    <>
      <MetaData title={"Register User"} />
      <div className="row wrapper">
        <div className="col-10 col-lg-5">
          <form
            onSubmit={handleSubmit}
            className="shadow-lg"
            encType="multipart/form-data"
          >
            <h1 className="mb-3">Register</h1>

            <div className="form-group">
              <label htmlFor="gender_field">Title</label>
              <select
                className="form-control"
                id="category_field"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              >
                {titles.map((title) => (
                  <option key={title} value={title}>
                    {title}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="email_field">Name</label>
              <input
                type="name"
                id="name_field"
                className="form-control"
                name="name"
                value={name}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email_field">Email</label>
              <input
                type="email"
                id="email_field"
                className="form-control"
                name="email"
                value={email}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password_field">Password</label>
              <input
                type="password"
                id="password_field"
                className="form-control"
                name="password"
                value={password}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="avatar_upload">Avatar</label>
              <div className="d-flex align-items-center">
                <div>
                  <figure className="avatar mr-3 item-rtl">
                    <img
                      src={avatarPreview}
                      className="rounded-circle"
                      alt="Avatar Preview"
                    />
                  </figure>
                </div>
                <div className="custom-file">
                  <input
                    type="file"
                    name="avatar"
                    className="custom-file-input"
                    id="customFile"
                    accept="images/*"
                    onChange={handleChange}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    Choose Avatar (optional)
                  </label>
                </div>
              </div>
            </div>

            <button
              id="register_button"
              type="submit"
              className="btn btn-block py-3"
              disabled={loading ? true : false}
            >
              {loading ? "Loading..." : "REGISTER"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
