export const ADMIN_PRODUCTS_REQUEST="ADMIN_PRODUCTS_REQUEST"
export const ADMIN_PRODUCTS_SUCCESS="ADMIN_PRODUCTS_SUCCESS"
export const ADMIN_PRODUCTS_FAIL="ADMIN_PRODUCTS_FAIL"

export const ALL_PRODUCTS_REQUEST = "ALL_PRODUCTS_REQUEST";
export const ALL_PRODUCTS_SUCCESS = "ALL_PRODUCTS_SUCCESS";
export const ALL_PRODUCTS_FAIL = "ALL_PRODUCTS_FAIL";

export const ALL_DISCOUNT_PRODUCTS_REQUEST = "ALL_DISCOUNT_PRODUCTS_REQUEST";
export const ALL_DISCOUNT_PRODUCTS_SUCCESS = "ALL_DISCOUNT_PRODUCTS_SUCCESS";
export const ALL_DISCOUNT_PRODUCTS_FAIL = "ALL_DISCOUNT_PRODUCTS_FAIL";

export const DISPLAY_IN_SAVE_YOUR_BUY_REQUEST = "DISPLAY_IN_SAVE_YOUR_BUY_REQUEST";
export const DISPLAY_IN_SAVE_YOUR_BUY_SUCCESS = "DISPLAY_IN_SAVE_YOUR_BUY_SUCCESS";
export const DISPLAY_IN_SAVE_YOUR_BUY_FAIL = "DISPLAY_IN_SAVE_YOUR_BUY_FAIL";

export const PRODUCTS_WITHOUT_DISCOUNT_REQUEST = "PRODUCTS_WITHOUT_DISCOUNT_REQUEST";
export const PRODUCTS_WITHOUT_DISCOUNT_SUCCESS = "PRODUCTS_WITHOUT_DISCOUNT_SUCCESS";
export const PRODUCTS_WITHOUT_DISCOUNT_FAIL = "PRODUCTS_WITHOUT_DISCOUNT_FAIL";

export const PRODUCTS_BY_CATEGORY_REQUEST="PRODUCTS_BY_CATEGORY_REQUEST";
export const PRODUCTS_BY_CATEGORY_SUCCESS="PRODUCTS_BY_CATEGORY_SUCCESS";
export const PRODUCTS_BY_CATEGORY_FAIL="PRODUCTS_BY_CATEGORY_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const NEW_PRODUCT_REQUEST = "NEW_PRODUCT_REQUEST";
export const NEW_PRODUCT_SUCCESS = "NEW_PRODUCT_SUCCESS"
export const NEW_PRODUCT_RESET = "NEW_PRODUCT_RESET";
export const NEW_PRODUCT_FAIL = "NEW_PRODUCT_FAIL"

export const DELETE_PRODUCT_REQUEST = "DELETE_PRODUCT_REQUEST";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT_RESET = "DELETE_PRODUCT_RESET";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL"

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_RESET = "UPDATE_PRODUCT_RESET";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL"

export const PRODUCT_REVIEW_REQUEST = "PRODUCT_REVIEW_REQUEST";
export const PRODUCT_REVIEW_SUCCESS = "PRODUCT_REVIEW_SUCCESS"
export const PRODUCT_REVIEW_RESET = "PRODUCT_REVIEW_RESET";
export const PRODUCT_REVIEW_FAIL = "PRODUCT_REVIEW_FAIL"

export const GET_REVIEWS_REQUEST = "GET_REVIEWS_REQUEST";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS"
export const GET_REVIEWS_FAIL = "GET_REVIEWS_FAIL"

export const DELETE_REVIEWS_REQUEST = "DELETE_REVIEWS_REQUEST"
export const DELETE_REVIEWS_SUCCESS = "DELETE_REVIEWS_SUCCESS"
export const DELETE_REVIEWS_RESET = "DELETE_REVIEWS_RESET"
export const DELETE_REVIEWS_FAIL = "DELETE_REVIEWS_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS";
