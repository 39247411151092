import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MetaData from "../layouts/MetaData";
import Loader from "../layouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { MDBDataTable } from "mdbreact";
import {
  clearErrors,
  getAdminProducts,
  deleteProduct,
} from "../../actions/productAction";
import Sidebar from "./Sidebar";
import { DELETE_PRODUCT_RESET } from "../../constants/productConstants";
import DeleteConfirmationModal from "../layouts/DeleteConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-free/css/all.min.css";

const ProductsList = ({ history }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = Number(localStorage.getItem("datatable-page"));
    return storedPage || 1;
  });
  const [productsPerPage, setProductsPerPage] = useState(() => {
    const storedProductsPerPage = Number(
      localStorage.getItem("products-per-page")
    );
    return storedProductsPerPage || 10;
  }); // Initial value for products per page
  const [searchQuery, setSearchQuery] = useState("");
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, products } = useSelector((state) => state.products);
  const { error: deleteError, isDeleted } = useSelector(
    (state) => state.product
  );

  useEffect(() => {
    dispatch(getAdminProducts());
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success("Product deleted successfully");
      history.push("/admin/products");
      dispatch({
        type: DELETE_PRODUCT_RESET,
      });
    }
  }, [dispatch, alert, error, deleteError, isDeleted, history]);

  useEffect(() => {
    localStorage.setItem("datatable-page", currentPage);
  }, [currentPage]);

  useEffect(() => {
    localStorage.setItem("products-per-page", productsPerPage);
  }, [productsPerPage]);

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleDelete = () => {
    dispatch(deleteProduct(selectedProductId));
    setShowDeleteModal(false);
  };
  const handleClose = () => {
    setShowDeleteModal(false);
  };

  const handleProductsPerPageChange = (e) => {
    const value = parseInt(e.target.value);
    setProductsPerPage(value);
    setCurrentPage(1); // Reset to the first page when changing products per page
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = products.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Calculate the range of products being displayed
  const currentProductsStart = indexOfFirstProduct + 1;
  const currentProductsEnd = Math.min(indexOfLastProduct, products.length);
  // Filter the products based on the search query
  const filteredProducts = products.filter((product) =>{
    const searchQueryLowerCase = searchQuery.toLowerCase();

    // Search by product ID, name, or size
    const idMatch = product._id.toLowerCase().includes(searchQueryLowerCase);
    const nameMatch = product.name.toLowerCase().includes(searchQueryLowerCase);
    const sizeMatch = product.size.toLowerCase().includes(searchQueryLowerCase);
    const colorMatch = product.color.toLowerCase().includes(searchQueryLowerCase);
  
    // Combine the search conditions using logical OR (||)
    return idMatch || nameMatch || sizeMatch || colorMatch;
  });

  const renderProducts = () => {
    if (searchQuery) {
      // Render your products using the `filteredProducts` array here
      return filteredProducts.map((product) => (
        <tr key={product._id}>
          <td>{product._id}</td>
          <td>{product.name}</td>
          <td>{product.category}</td>
          <td>{`AED ${product.price}`}</td>
          <td>{product.size}</td>
          <td>{product.color}</td>
          <td>{product.discount}</td>
          <td>{product.stock}</td>
          <td>
            <Link to={`/admin/product/${product._id}`} className="py-1 px-1">
              <FontAwesomeIcon
                icon={faPencil}
                className={
                  product.stock === 0
                    ? "text-white py-1 px-1"
                    : "text-info py-1 px-1"
                }
              />
            </Link>
            <Link
              to="#"
              className="py-1"
              onClick={() => {
                setShowDeleteModal(true);
                setSelectedProductId(product._id);
              }}
            >
              <FontAwesomeIcon
                icon={faTrash}
                className={
                  product.stock === 0
                    ? "text-white py-1 px-1"
                    : "text-danger py-1 px-1"
                }
              />
            </Link>
            <DeleteConfirmationModal
              show={showDeleteModal}
              handleClose={handleClose}
              handleDelete={handleDelete}
            />
          </td>
        </tr>
      ));
    } else {
      // Render your products using the `currentProducts` array here

      return currentProducts.map((product) => (
        <tr
          key={product._id}
          className={product.stock === 0 ? "bg-danger text-white" : ""}
        >
          <td>{product._id}</td>
          <td>{product.name}</td>
          <td>{product.category}</td>
          <td>{`AED ${product.price}`}</td>
          <td>{product.size}</td>
          <td>{product.color}</td>
          <td>{product.discount}</td>
          <td>{product.stock}</td>
          <td>
            <Link to={`/admin/product/${product._id}`} className="py-1 px-1">
              <FontAwesomeIcon
                icon={faPencil}
                className={
                  product.stock === 0
                    ? "text-white py-1 px-1"
                    : "text-info py-1 px-1"
                }
              />
            </Link>
            <Link
              to="#"
              className="py-1"
              onClick={() => {
                setShowDeleteModal(true);
                setSelectedProductId(product._id);
              }}
            >
              <FontAwesomeIcon
                icon={faTrash}
                className={
                  product.stock === 0
                    ? "text-white py-1 px-1"
                    : "text-danger py-1 px-1"
                }
              />
            </Link>
            <DeleteConfirmationModal
              show={showDeleteModal}
              handleClose={handleClose}
              handleDelete={handleDelete}
            />
          </td>
        </tr>
      ));
    }
  };

  const totalPages = Math.ceil(products.length / productsPerPage);

  return (
    <>
    <MetaData title={"Products List"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        {/* ... (existing code) */}
        <div className="col-12 col-md-10">
          <>
          <h2 className="my-5">All Products</h2>
          <div className="row my-2">
            <div className="form-group col-sm-5 flex-column d-flex">
              <label htmlFor="name_field">Entries per page:</label>
              <select
                id="productsPerPage"
                className="form-control"
                type="number"
                value={productsPerPage}
                onChange={handleProductsPerPageChange}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                {/* Add more options for different products per page if desired */}
              </select>
            </div>
            <div className="ml-auto mr-2 form-group col-sm-5 flex-column d-flex">
              <label htmlFor="name_field">Search</label>
              <input
                type="text"
                id="name_field"
                className="form-control"
                placeholder="Search For Product"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
          <div className="mr-2">
          <div className="table-responsive">
            <table className="table table-bordered table-hover table-responsive table-striped">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Price</th>
                  <th>Size</th>
                  <th>Color</th>
                  <th>Discount</th>
                  <th>Stock</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{renderProducts()}</tbody>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Price</th>
                  <th>Size</th>
                  <th>Color</th>
                  <th>Discount</th>
                  <th>Stock</th>
                  <th>Actions</th>
                </tr>
              </thead>
            </table>
          </div>
          {/* Place the "Showing X to Y of Z entries" message here */}
          <p className="mb-2">
            Showing {currentProductsStart} to {currentProductsEnd} of{" "}
            {products.length} entries
          </p>
          </div>
          {/* ... (optional: custom pagination controls if needed) */}
          <div className="d-flex justify-content-center">
            <nav aria-label="Page navigation">
              <ul className="pagination bg-white">
                {/* Add previous button */}
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button className="page-link" onClick={handlePreviousPage}>
                    Previous
                  </button>
                </li>

                {Array.from({ length: totalPages }).map((_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      index + 1 === currentPage ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => onPageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}

                {/* Add next button */}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button className="page-link" onClick={handleNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          </>
        </div>
       
      </div>
    </>
  );
};

export default ProductsList;
