import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { login, clearErrors} from "../../actions/userAction";
import { Link } from "react-router-dom";
import Loader from "../layouts/Loader";
import MetaData from "../layouts/MetaData";


const Login = ({ history, location }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const alert = useAlert();
  const dispatch = useDispatch();
  const { user, isAuthenticated, error, loading, isVerified } = useSelector(
    (state) => state.auth
  );

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (isAuthenticated && user.isVerified) {
      
      history.push(redirect);
      
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, alert, isAuthenticated, error, history]);

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(login(email, password));
  };

 

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MetaData title={"Login"} />
          <div className="container container-fluid">
            <div className="row wrapper">
              <div className="col-10 col-lg-5">
                <form onSubmit={handleSubmit} className="shadow-lg">
                  <h1 className="mb-3">Login</h1>
                  <div className="form-group">
                    <label htmlFor="email_field">Email</label>
                    <input
                      type="email"
                      id="email_field"
                      className="form-control"
                      value={email}
                      onChange={handleEmail}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="password_field">Password</label>
                    <input
                      type="password"
                      id="password_field"
                      className="form-control"
                      value={password}
                      onChange={handlePassword}
                    />
                  </div>

                  <Link to="/password/forgot" className="float-right mb-4">
                    Forgot Password?
                  </Link>

                  {isAuthenticated &&(
                    <div className="alert alert-danger" role="alert">
                      You need to verify your email before logging in.
                    </div>
                  )}


                  <button
                    id="login_button"
                    type="submit"
                    className="btn btn-block py-3"
                    history={history}
                  >
                    LOGIN
                  </button>
                  {/* <br />
                  <h5 className="text-center">OR</h5> */}

                  {/* <GoogleLogin
                    clientId={clientId}
                    buttonText="Sign in with Google"
                    onSuccess={responseGoogle}
                    onFailure={onFailure}
                    cookiePolicy={"single_host_origin"}
                    className="mx-auto"
                  /> */}
                  
                  {/* <button className="btn btn-google">Login with Google</button> */}

                  <hr />
                  <Link to="/register" className="float-right">
                    New User?
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
