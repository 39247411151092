import React, { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductsWithoutDiscount } from "../../actions/productAction";
import Product from "../products/Product";
import { useAlert } from "react-alert";
import "rc-slider/assets/index.css";
import { useHistory} from "react-router-dom";

import { Fade, Bounce} from "react-reveal";

const FeaturedCollections = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.products);

  const { productsWithoutDiscount } = useSelector(
    (state) => state.productsWithoutDiscount
  );

  const history = useHistory();

  useEffect(() => {
    if (error) {
      return alert.error(error);
    }

    dispatch(getProductsWithoutDiscount());

    const handleScroll = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY.toString());
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch, alert, error, history.location.search]);

  useEffect(() => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");

    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
    }

    // Clear the scroll position from session storage after restoring it
    sessionStorage.removeItem("scrollPosition");
  }, []);

  const handleViewAllClick = () => {
    history.push("/allproducts"); // Step 3
    window.scrollTo(0, 0); // Scroll to the top of the page when navigating to AllProducts.js
  };
  return (
    <div className="col-md-12">
    <div className="container">
      <section id="products" className="mt-5 container">
        <h2 id="products_heading" className="artah-text-purple">
          Featured Collections
        </h2>
        <Fade duration={2000}>
          <div className="row">
            {productsWithoutDiscount &&
              productsWithoutDiscount

                .slice(0, 8)
                .map((product) => (
                  <Product key={product._id} product={product} col={3} />
                ))}
          </div>
        </Fade>
      </section>

      <div className="d-flex mx-auto justify-content-center my-3">
        <Bounce duration={3000}>
          <button
            className="btn border artah-purple px-5 mx-auto text-white"
            onClick={handleViewAllClick}
          >
            View All
          </button>
        </Bounce>
      </div>
      </div>
    </div>
  );
};

export default FeaturedCollections;
