import React from "react";
import "../../App.css";
import { Link, Route, useLocation, matchPath } from "react-router-dom";
import Search from "./Search";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { logout } from "../../actions/userAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import MenuHeader from "./MenuHeader";

const Header = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { user, isAuthenticated, loading } = useSelector((state) => state.auth);
  const { cartItems } = useSelector((state) => state.cart);
  const logoutHandler = () => {
    dispatch(logout());

    alert.success("Successfully Logged out");
  };
  const location = useLocation();
  const isDashboard = location.pathname === "/dashboard";
  const isAllProducts = location.pathname === "/admin/products";
  const isAddNewProduct = location.pathname === "/admin/product";
  const isAllUsers = location.pathname === "/admin/users";
  const isAllOrders = location.pathname === "/admin/orders";
  const isReviews = location.pathname === "/admin/reviews";
  const isProductUpdate = !!matchPath(location.pathname, {
    path: "/admin/product/:id",
    exact: true,
    strict: false,
  });
  
  const isOrderProcess = !!matchPath(location.pathname, {
    path: "/admin/order/:id",
    exact: true,
    strict: false,
  });
  
  const isUserUpdate = !!matchPath(location.pathname, {
    path: "/admin/user/:id",
    exact: true,
    strict: false,
  });
  const isOutOfStock = location.pathname === "/admin/outofstock";
  const isOfferItems =location.pathname==="/admin/discountitems";

  if (
    isDashboard ||
    isAllProducts ||
    isAddNewProduct ||
    isAllUsers ||
    isAllOrders ||
    isReviews ||
    isProductUpdate ||
    isOrderProcess ||
    isUserUpdate ||
    isOutOfStock ||
    isOfferItems
  ) {
    // Return null if not on the home page to hide the carousel
    return null;
  }

  return (
    <>
      <div className="container navbar d-flex bg-white">
        <div className="col-4 col-md-3 col-sm-4">
          <div className="navbar-brand">
            <Link to="/">
              <img
                src="/images/artah_logo.png"
                alt=""
                className="logo-img img-fluid"
              />
            </Link>
          </div>
        </div>

        <div className="col-6 col-md-6 col-sm-4 d-none d-sm-inline">
          <Route render={({ history }) => <Search history={history} />} />
        </div>

        <div className="col-4 col-md-3 col-sm-4 mt-sm-0 pl-4 pl-md-0 pl-lg-0 text-center">
          <Link to="/cart" style={{ textDecoration: "none" }}>
            <span id="cart">
              <i
                className="fa fa-shopping-cart fa-lg"
                aria-hidden="true"
                style={{ color: "#51213B" }}
              ></i>
            </span>
            <span className="ml-1 text-white" id="cart_count">
              {cartItems.length}
            </span>
            <span
              className="ml-2 d-none d-sm-inline"
              style={{ color: "#6E0D44" }}
            >
              My Cart
            </span>
          </Link>
        </div>

        <div className="row col-4 col-md-3 col-sm-4 d-lg-none mr-2 d-flex">
          {isAuthenticated && user.isVerified ? (
            <div className="mx-auto dropdown d-inline d-none d-sm-inline">
              <Link
                to="#!"
                className="btn dropdown-toggle text-white mr-4"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <figure className="avatar avatar-nav">
                  <img
                    src={user.avatar && user.avatar.url}
                    alt={user && user.name}
                    className="rounded-circle img-fluid"
                  />
                </figure>
                <span style={{ color: "#e69637" }}>{user && user.name}</span>
              </Link>
              <div
                className="dropdown-menu"
                aria-labelledby="dropDownMenuButton"
              >
                {user && user.role === "admin" && (
                  <Link className="dropdown-item text-white" to="/dashboard">
                    Dashboard
                  </Link>
                )}
                <Link className="dropdown-item text-white" to="/orders/me">
                  Orders
                </Link>
                <Link className="dropdown-item text-white" to="/me">
                  Profile
                </Link>
                <Link
                  className="dropdown-item text-white"
                  to="/"
                  onClick={logoutHandler}
                >
                  Logout
                </Link>
              </div>
            </div>
          ) : (
            !loading && (
              <>
                <a className="nav-link artah-text-purple" href="/login">
                  <FontAwesomeIcon icon={faUser} /> Login
                </a>

                <span className="text-white my-auto d-none d-sm-inline">|</span>

                <a
                  className="nav-link text-warning d-none d-sm-inline"
                  href="/register"
                >
                  Register
                </a>
              </>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
