import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";

import MetaData from "../layouts/MetaData";
import Loader from "../layouts/Loader";
import Sidebar from "./Sidebar";

import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { getReviews, deleteReviews, clearErrors } from "../../actions/productAction";
import { DELETE_REVIEWS_RESET } from "../../constants/productConstants";

const ProductReviews = ({history}) => {
  const [productId, setProductId] = useState("");
  const alert = useAlert();
  const dispatch = useDispatch();

  const { loading, error, reviews } = useSelector(
    (state) => state.productReviews
  );
  const { isDeleted,  } = useSelector(state => state.deleteReview)

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (isDeleted) {
        alert.success('review deleted successfully');
        dispatch({ type: DELETE_REVIEWS_RESET })
    }

    if (productId !== "") {
      dispatch(getReviews(productId));
    }
  }, [dispatch, alert, error, productId, isDeleted]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getReviews(productId));
  };

  const reviewDeleteHandler = (id) => {
    dispatch(deleteReviews(id, productId))
  }

  const setReviews = () => {
    const data = {
      columns: [
        {
          label: "Review ID",
          field: "id",
          sort: "asc",
        },
        {
          label: "Rating",
          field: "rating",
          sort: "asc",
        },
        {
          label: "Comment",
          field: "comment",
          sort: "asc",
        },
        {
          label: "user",
          field: "user",
          sort: "asc",
        },
        {
          label: "Actions",
          field: "actions",
        },
      ],
      rows: [],
    };

    reviews.forEach((review) => {
      data.rows.push({
        id: review._id,
        rating: review.rating,
        comment: review.comment,
        user: review.name,
        actions: (
          <>
            <button className="btn btn-danger py-1 px-2 ml-2"
            onClick={()=>reviewDeleteHandler(review._id)}>
              <i className="fa fa-trash"></i>
            </button>
          </>
        ),
      });
    });

    return data;
  };
  return (
    <>
      <MetaData title={"All Users"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        <div className="col-12 col-md-10">
          <>
            <div className="row justify-content-center mt-5">
              <div className="col-5">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="productId_field">Enter Product ID</label>
                    <input
                      type="text"
                      id="productId_field"
                      className="form-control"
                      value={productId}
                      onChange={(e) => setProductId(e.target.value)}
                    />
                  </div>

                  <button
                    id="search_button"
                    type="submit"
                    className="btn btn-primary btn-block py-2"
                  >
                    SEARCH
                  </button>
                </form>
              </div>
            </div>
            {reviews && reviews.length > 0 ? (
              <MDBDataTable
                data={setReviews()}
                className="px-3"
                bordered
                striped
                hover
              />
            ) : (
              <p className="mt-5 text-center">No Reviews</p>
            )}
          </>
        </div>
      </div>
    </>
  );
};

export default ProductReviews;
