import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import axios from "axios";
import Footer from "./components/layouts/Footer";
import Header from "./components/layouts/Header";
import Home from "./components/Home";
import ProductDetails from "./components/products/ProductDetails";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import { loadUser, googleLogin } from "./actions/userAction";
import store from "./store";

// Auth or User Imports
import Login from "./components/users/Login";
import Register from "./components/users/Register";
import Profile from "./components/users/Profile";
import UpdateProfile from "./components/users/UpdateProfile";
import UpdatePassword from "./components/users/UpdatePassword";
import ForgotPassword from "./components/users/ForgotPassword";
import ResetPassword from "./components/users/ResetPassword";

// Cart Imports
import Cart from "./components/carts/Cart";
import Shipping from "./components/carts/Shipping";
import ConfirmOrder from "./components/carts/ConfirmOrder";
import Payment from "./components/carts/Payment";
import OrderSuccess from "./components/carts/OrderSuccess";

// Order Imports
import ListOrders from "./components/orders/ListOrders";
import OrderDetails from "./components/orders/OrderDetails";

// Stripe Imports
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Admin Imports
import Dashboard from "./components/admin/Dashboard";
import ProductsList from "./components/admin/ProductsList";
import NewProduct from "./components/admin/NewProduct";
import UpdateProduct from "./components/admin/UpdateProduct";
import OrdersList from "./components/admin/OrdersList";
import ProcessOrder from "./components/admin/ProcessOrder";
import UsersList from "./components/admin/UsersList";
import UpdateUser from "./components/admin/UpdateUser";
import ProductReviews from "./components/admin/ProductReviews";
import MenuHeader from "./components/layouts/MenuHeader";
import TopHeader from "./components/layouts/TopHeader";
import MyCarousel from "./components/MyCarousel";
import COD from "./components/carts/COD";

// import all product pages
import MenGarments from "./components/pages/MenGarments";
import WomenGarments from "./components/pages/WomenGarments";
import ChildrenGarments from "./components/pages/ChildrenGarments";
import MenFootwear from "./components/pages/MenFootwear";
import WomenFootwear from "./components/pages/WomenFootwear";
import ChildrenFootwear from "./components/pages/ChildrenFootwear";
import PersonalCare from "./components/pages/PersonalCare";
import MotherBabyCare from "./components/pages/MotherBabyCare";
import MakeupnCosmetics from "./components/pages/MakeupnCosmetics";
import HomeLinens from "./components/pages/HomeLinens";
import GiftItems from "./components/pages/GiftItems";
import ImitationJewellery from "./components/pages/ImitationJewellery";
import FAQ from "./components/pages/FAQ.js";
import VerificationSuccess from "./components/users/VerificationSuccess.js";
import DiscountProducts from "./components/pages/DiscountProducts";
import AllProducts from "./components/pages/AllProducts";
import OutOfStock from "./components/admin/OutOfStock";
import DiscountItems from "./components/admin/DiscountItems";






function App() {
  // const [stripeApiKey, setStripeApiKey] = useState("");
  // useEffect(() => {
  //   store.dispatch(loadUser());

  //   async function getStripeApiKey() {
  //     const { data } = await axios.get("/api/v1/stripeapi");
  //     setStripeApiKey(data.stripeApiKey);
  //   }

  //   getStripeApiKey();
  // }, []);
  
  useEffect(() => {
       store.dispatch(loadUser());
  },[]);

  return (
    <div>
      <Router>
        <TopHeader/>
        
        <Header />
        <MenuHeader/>
        <MyCarousel />
        <Route exact path="/" component={Home} />
        <div className="container">
         
          <Route path="/search/:keyword" component={Home} />
          {/* all product pages */}
          <Route exact path="/mengarments" component={MenGarments} />
          <Route exact path="/womengarments" component={WomenGarments}/>
          <Route exact path="/childrengarments" component={ChildrenGarments} />
          <Route exact path="/menfootwear" component={MenFootwear} />
          <Route exact path="/womenfootwear" component={WomenFootwear} />
          <Route exact path="/childrenfootwear" component={ChildrenFootwear} />
          <Route exact path="/personalcare" component={PersonalCare} />
          <Route exact path="/motherbabycare" component={MotherBabyCare} />
          <Route exact path="/makeupncosmetics" component={MakeupnCosmetics} />
          <Route exact path="/homelinens" component={HomeLinens} />
          <Route exact path="/giftitems" component={GiftItems} />
          <Route exact path="/imitationjewellery" component={ImitationJewellery} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/allproducts" component={AllProducts} />
          <Route exact path="/discountproducts" component={DiscountProducts} />
         
          {/* end of product pages */}
          <Route exact path="/product/:id" component={ProductDetails} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          
          <Route exact path="/password/forgot" component={ForgotPassword} />
          <Route
            exact
            path="/password/reset/:token"
            component={ResetPassword}
          />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/verify-email/:token" component={VerificationSuccess}/>
          <ProtectedRoute exact path="/me" component={Profile} />
          <ProtectedRoute exact path="/me/update" component={UpdateProfile} />
          <ProtectedRoute
            exact
            path="/password/update"
            component={UpdatePassword}
          />
          <ProtectedRoute exact path="/shipping" component={Shipping} />
          <ProtectedRoute exact path="/confirm" component={ConfirmOrder} />
          {/* {stripeApiKey && (
            <Elements stripe={loadStripe(stripeApiKey)}>
              <ProtectedRoute exact path="/payment" component={Payment} />
            </Elements>
          )} */}
          <ProtectedRoute exact path="/cod" component={COD} />
          <ProtectedRoute exact path="/success" component={OrderSuccess} />
          <ProtectedRoute exact path="/orders/me" component={ListOrders} />
          <ProtectedRoute exact path="/order/:id" component={OrderDetails} />
        </div>
        <ProtectedRoute
          exact
          path="/dashboard"
          isAdmin={true}
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path="/admin/products"
          isAdmin={true}
          component={ProductsList}
        />
        <ProtectedRoute
          exact
          path="/admin/product"
          isAdmin={true}
          component={NewProduct}
        />
        <ProtectedRoute
          exact
          path="/admin/product/:id"
          isAdmin={true}
          component={UpdateProduct}
        />
        <ProtectedRoute
          exact
          path="/admin/orders"
          isAdmin={true}
          component={OrdersList}
        />
        <ProtectedRoute
          exact
          path="/admin/order/:id"
          isAdmin={true}
          component={ProcessOrder}
        />
        <ProtectedRoute
          exact
          path="/admin/users"
          isAdmin={true}
          component={UsersList}
        />
        <ProtectedRoute
          exact
          path="/admin/user/:id"
          isAdmin={true}
          component={UpdateUser}
        />
        <ProtectedRoute
          exact
          path="/admin/reviews"
          isAdmin={true}
          component={ProductReviews}
        />
        <ProtectedRoute
          exact
          path="/admin/outofstock"
          isAdmin={true}
          component={OutOfStock}
        />
        <ProtectedRoute
          exact
          path="/admin/discountitems"
          isAdmin={true}
          component={DiscountItems}
        />

<Footer />
      </Router>
     

     
    </div>
  );
}

export default App;
